.menu {
    @include transition;

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1010;
    opacity: 0;
    pointer-events: none;

    &::after {
        @include transition;

        content: ' ';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba($c-black, 0.2);
        z-index: -1;
        opacity: 0; }

    &-active {
        .menu {
            opacity: 1;
            pointer-events: all;

            &::after {
                opacity: 1; }

            &__in {
                transform: translate3d(0%, 0, 0); } } }

    &__in {
        @include transition;

        width: 260px;
        height: 100%;
        padding: 20px 16px;
        box-sizing: border-box;
        background: $c-white;
        position: relative;
        overflow: hidden;
        transform: translate3d(-100%, 0, 0); }

    &__item {
        padding: 0 0 24px 0;
        margin: 0 0 24px 0;
        border-bottom: 1px solid $c-grey;

        &:last-of-type {
            padding: 0;
            margin: 0;
            border-bottom: none; } }

    &-nav {
        ul {
            li {
                margin: 0 0 20px 0;

                a {
                    @include link;
                    @include mobile-main-text;

                    display: block; } } }


        &__link {
            &--submenu {
                position: relative;

                &::after {
                    content: ' ';
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translate(0, -50%);
                    width: 24px;
                    height: 24px;
                    background: url('../img/icons/arrow-menu.svg') center center no-repeat;
                    background-size: contain; } } } }

    &__control {
        margin: 0 0 24px 0;

        &:last-of-type {
            margin: 0; }

        .btn {
            &__sign {
                @include mobile-main-text;

                height: 20px;
                line-height: 20px;
                margin: 0 8px 0 16px; }

            &__counter {
                position: static;
                width: 18px;
                height: 18px;
                line-height: 18px; } } }

    &__info {
        p {
            @include mobile-main-text;

            display: block;
            margin: 0 0 12px 0;
            color: $c-gray-4; } }

    .footer {
        &-socials {
            margin: 0 -8px;

            &__item {
                margin: 0 8px;
                background-color: $c-grey;

                &:hover {
                    background-color: $c-dark-grey; } } } }

    &-sub {
        @include transition;

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: $c-white;
        transform: translate3d(100%, 0, 0);
        padding: 20px 16px;
        box-sizing: border-box;
        z-index: 10;

        &.is-active {
            transform: translate3d(0%, 0, 0); }

        &__btn {
            margin: 0 0 16px 0; }

        &__title {
            @include mobile-small-head;

            display: block;
            margin: 0 0 20px 0; }

        &__menu {
            margin: 0 0 16px 0; }

        &__nav {
            ul {
                -webkit-column-count: 2;
                column-count: 2;

                li {
                    margin: 0 0 24px;

                    a {
                        @include link;
                        @include mobile-main-text;

                        color: $c-black; } } } } } }
