.product {
    &-mini {
        display: block;
        width: 60px;
        height: 60px;
        margin: 0 8px 0 0;

        &__items {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            margin: 0 0 28px 0;
            width: 100%;
            height: 60px;
            padding: 0 76px 0 0;
            box-sizing: border-box;
            overflow: hidden;

            @include tablet-big {
                margin: 0 0 24px 0; }

            .btn {
                position: absolute;
                top: 12px;
                right: 0; } }

        &__img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 4px;
            overflow: hidden; } } }
