.about {
    margin: 0 0 100px 0;

    @include tablet-big {
        margin: 0 0 48px 0; }

    &__in {
        display: flex;
        align-items: center;

        @include tablet-big {
            flex-direction: column;
            align-items: flex-start; } }

    &__l {
        flex: 0 0 360px;

        @include tablet-big {
            flex: 0 0 100%;
            margin: 0 0 32px 0; } }

    &__visual {
        display: block;
        width: 100%;
        border-radius: 4px;
        overflow: hidden; }

    &__img {
        display: block;
        width: 100%; }

    &__r {
        flex: 1 1 auto;
        padding: 30px 0 30px 140px;
        box-sizing: border-box;

        @include tablet-big {
            padding: 0; } }

    &__headline {
        @include head;

        display: block;
        margin: 0 0 32px 0;

        @include tablet-big {
            margin: 0 0 12px 0; } }

    &__desc {
        margin: 0 0 32px 0;

        @include tablet-big {
            margin: 0 0 24px 0; }

        p {
            @include main-text; } } }
