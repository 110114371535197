.product {
    margin: 0 0 32px 0;

    &__img {
        overflow: hidden;
        width: 100%; }

    &__in {
        display: flex;
        position: relative;

        @include tablet-big {
            flex-direction: column; } }

    &__head {
        margin: 0 0 40px 0;

        @include tablet-big {
            margin: 0 0 4px 0; } }

    &__title {
        @include big-head;

        display: block;
        margin: 0 0 28px 0;

        @include tablet-big {
            @include mobile-head;

            margin: 0 0 12px 0; } }

    &__in {
        display: flex; }

    &__l {
        flex: 1 1 auto;
        margin: 0 100px 0 0;
        // padding: 0 460px 0 0
        box-sizing: border-box;
        overflow: hidden;

        @include tablet-big {
            margin: 0 -16px;
            flex: 1 1 100%; } }

    &-gallery {
        margin: 0 0 32px 0;

        @include tablet-big {
            margin: 0 0 40px 0;
            position: relative;
            height: 0;
            padding-bottom: 73%; }

        &__item {
            position: relative;
            width: 100%;
            height: 100%;
            cursor: crosshair; }

        &__big {
            width: 100%;
            height: 500px;
            font-size: 0;
            margin: 0 0 32px 0;

            @include tablet-big {
                margin: 0;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                height: 100%; }

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;

                @include tablet-big {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0; } }

            .slick {
                &-list,
                &-track {
                    height: 100%; }

                &-dots {
                    position: absolute;
                    right: 0;
                    bottom: -8px;
                    left: 0;
                    display: flex;
                    padding: 0 16px;

                    li {
                        flex-grow: 1;
                        background: transparent;
                        outline: none;
                        box-shadow: none;
                        padding: 0;
                        margin: 0;
                        border: none;
                        height: 8px;

                        &.slick {
                            &-active {
                                button {
                                    &::after {
                                        background: $c-black; } } } }

                        button {
                            position: relative;
                            display: block;
                            width: 100%;
                            height: 100%;
                            background: transparent;
                            outline: none;
                            box-shadow: none;
                            padding: 0;
                            margin: 0;
                            border: none;

                            &::after {
                                content: ' ';
                                position: absolute;
                                top: 50%;
                                transform: translate(0, -50%);
                                right: 0;
                                left: 0;
                                height: 2px;
                                background: $c-gray; } } } } } }



        &__mini {
            width: 100%;
            padding: 0 126px;
            box-sizing: border-box;
            position: relative;

            @include tablet-big {
                display: none; }

            img {
                @include transition;

                display: block;
                height: 101px;
                object-fit: cover;
                margin: 0 6px;
                cursor: pointer;
                opacity: 0.3;

                &:hover,
                &:focus,
                &:hover:focus {
                    outline: none;
                    box-shadow: none; } }

            .slick {
                &-current {
                    opacity: 1; }

                &-arrow {
                    padding: 0;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    cursor: pointer;
                    width: 36px;
                    height: 100%;
                    background: transparent;
                    text-indent: -99999px;
                    border: none;
                    outline: none;
                    box-shadow: none;

                    &:hover {
                        &::after {
                            border-color: $c-dark-grey; } } }

                &-prev {
                    position: absolute;
                    left: 32px;

                    &::after {
                        content: ' ';
                        position: absolute;
                        top: 50%;
                        transform: translate(0, -50%);
                        left: 0;

                        width: 100%;
                        height: 36px;
                        box-sizing: border-box;
                        border: 1px solid $c-gray-4;
                        border-radius: 50%;
                        background: url('../img/icons/arrow-prev.svg') center center no-repeat;
                        background-size: contain; }

                    &:hover {
                        &::after {
                            background: $c-dark-grey url('../img/icons/arrow-prev-white.svg') center center no-repeat;
                            background-size: contain; } } }


                &-next {
                    position: absolute;
                    right: 32px;

                    &::after {
                        content: ' ';
                        position: absolute;
                        top: 50%;
                        transform: translate(0, -50%);
                        right: 0;

                        width: 100%;
                        height: 36px;
                        box-sizing: border-box;
                        border: 1px solid $c-gray-4;
                        border-radius: 50%;
                        background: url('../img/icons/arrow-next.svg') center center no-repeat;
                        background-size: contain; }

                    &:hover {
                        &::after {
                            background: $c-dark-grey url('../img/icons/arrow-next-white.svg') center center no-repeat;
                            background-size: contain; } } } } } }

    &__r {
        flex: 0 0 360px;
        // width: 360px
        position: static;
 }        // right: 0

    &__price {
        @include head;

        display: block;
        margin: 0 0 12px 0;

        @include tablet-big {
            margin: 0 0 8px 0; } }

    &__vendor {
        &-code {
            color: $c-gray-4; } }

    &-details {
        &__in {}


        &__top {
            position: relative;
            margin: 0 0 40px 0;

            @include tablet-big {
                padding: 0 92px 0 0;
                margin: 0 0 24px 0; }

            .product {
                &-details {
                    &__controls {
                        display: flex;
                        position: absolute;
                        top: 0;
                        right: 0;

                        .btn {
                            @include transition;

                            width: 40px;
                            height: 40px;
                            margin: 0 0 0 12px;
                            border-radius: 50%;

                            &:first-of-type {
                                @include tablet-big {
                                    margin: 0; } }

                            &:hover {
                                background: $c-gray; } } } } } }

        &__controls {
            margin: 0 0 40px 0;

            @include tablet-big {
                margin: 0 0 24px 0; }

            &-top {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 0 0 16px 0;

                @include tablet-big {
                    margin: 0 0 12px 0; } } }

        &__title {
            @include small-head;

            display: block; }

        &__info {
            margin: 0 0 40px 0;

            .product {
                &-details {
                    &__title {
                        margin: 0 0 20px 0; } } } }

        &__feature {
            span {
                @include main-text;

                &:first-of-type {
                    color: $c-gray-4;
                    flex: 0 0 100px;
                    margin: 0 40px 0 0;

                    @include tablet-big {
                        margin: 0 36px 0 0; } }

                &:nth-child(2) {
                    color: $c-black;
                    flex-grow: 1; } } }
        &__row {
            display: flex;
            padding-bottom: 8px;
            margin-bottom: 8px;
            border-bottom: 1px solid $c-gray;

            &:last-of-type {
                margin: 0;
                border-bottom: none; } } }

    &__tabs {
        @include tablet-big {
            margin: 0 0 24px 0;
            display: flex;
            flex-direction: column; } }

    &-counter {
        display: flex;
        align-items: center;

        &__control {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 44px;
            height: 44px;
            user-select: none;
            border-radius: 4px;
            background: $c-grey;

            @include tablet-big {
                width: 36px;
                height: 36px; } }

        & > input {
            @include main-text;

            padding: 0;
            display: block;
            width: 48px;
            height: 44px;
            border: none;
            box-shadow: none;
            text-align: center;
            user-select: none;

            @include tablet-big {
                width: 36px;
                height: 36px; } } }

    &-socials {
        display: flex;
        align-items: center;
        margin: 0 0 40px 0;

        @include tablet-big {
            margin: 0 0 24px 0; }

        &__items {
            display: flex;
            align-items: center;
            margin: 0 0 0 24px;

            .btn {
                margin: 0 0 0 8px;

                &:first-of-type {
                    margin: 0; } } } }

    &-consultation {
        padding: 40px;
        border-radius: 4px;
        box-shadow: 0px 0px 12px rgba(48, 48, 48, 0.07);

        @include tablet-big {
            padding: 24px; }

        .product {
            &-details {
                &__title {
                    margin: 0 0 12px 0; } } }

        &__description {
            margin: 0 0 24px 0;

            p {
                @include main-text; } }

        &__btn {
            .btn {
                @include tablet-big {
                    width: 100%; } } } } }
