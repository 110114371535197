.categories {
    margin: 0 0 100px 0;

    &__items {
        display: flex;
        margin: 0 -7px;

        @include tablet-big {
            margin: 0 -16px; }

        .slick {
            &-list {
                @include tablet-big {
                    padding: 0 0 0 16px; } } } }

    &-item {
        width: 25%;
        padding: 0 7px;
        box-sizing: border-box;

        @include tablet-big {
            display: block;
            width: 180px;
            height: 180px;
            padding: 0;
            margin: 0 16px 0 0; }

        &__in {
            display: block;
            width: 100%;
            padding-bottom: 107.14%;
            position: relative;
            border-radius: 4px;
            overflow: hidden;
            transform: translateZ(0);
            text-decoration: none;
            outline: none;

            @include tablet-big {
                width: 180px;
                height: 100%;
                padding-bottom: 0; }

            &:hover {
                .categories {
                    &-item {
                        &__visual {
                            transform: translate3d(0,0,0) scale(1.1);

                            @include tablet-big {
                                transform: translate3d(0,0,0) scale(1); } } } } } }

        &__visual {
            @include transition;

            transform: translate3d(0,0,0) scale(1);
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border-radius: 4px;
            overflow: hidden;
            z-index: -1;

            &::after {
                content: ' ';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: rgba(#151515, 0.5); } }

        &__img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%; }

        &__content {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            padding: 40px;

            @include tablet-big {
                width: 100%;
                height: 100%;
                padding: 20px;
                box-sizing: border-box;
                z-index: 10; } }

        &__name {
            @include head;

            color: $c-white;
            display: block;
            margin: 0 0 12px 0;

            @include tablet-big {
                margin: 0 0 8px 0; } }

        &__desc {
            @include main-text;

            display: block;
            min-height: 56px;
            color: $c-white;

            @include tablet-big {
                min-height: 36px; } } } }
