.catalog {
    margin: 0 0 100px 0;

    @include tablet-big {
        margin: 0 -16px 48px -16px; }

    &__in {
        display: flex;
        background: $c-grey;

        @include tablet-big {
            flex-direction: column;
            padding: 30px 16px; } }

    &__l {
        width: 50%;

        @include tablet-big {
            width: 100%; } }

    &__visual {
        width: 100%;
        height: 100%;

        @include tablet-big {
            margin: 0 0 24px 0; } }

    &__r {
        width: 50%;
        padding: 104px 80px;
        box-sizing: border-box;

        @include tablet-big {
            width: 100%;
            padding: 0; } }

    &__headline {
        @include head;

        display: block;
        margin: 0 0 32px 0;

        @include tablet-big {
            margin: 0 0 12px 0; } }

    &__desc {
        margin: 0 0 32px 0;

        @include tablet-big {
            margin: 0 0 24px 0; }

        p {
            @include main-text; } } }
