.products {
    margin: 0 0 100px 0;

    @include tablet-big {
        margin: 0 0 48px 0; }

    &__head {
        margin: 0 0 40px 0;

        @include tablet-big {
            margin: 0 0 20px 0; } }

    &__headline {
        .headline {
            margin: 0 0 28px 0;

            @include tablet-big {
                margin: 0 0 12px 0; } } }

    &__search {
        margin: 40px 0 0 0;

        @include tablet-big {
            margin: 12px 0 0 0; } }

    &__in {
        display: flex; }


    &-sidebar {
        position: sticky;
        bottom: 0;
        align-self: flex-end;
        flex: 0 0 200px;
        margin: 0 60px 0 0;

        @include tablet-big {
            display: none; }

        &__items {

            bottom: 0px;
            display: flex;
            flex-direction: column; }


        &__item {
            width: 100%;

            &:first-child {
                .products {
                    &-sidebar {
                        &__link {
                            border-top: 1px solid $c-grey; } } } } }

        &__link {
            @include transition;
            @include link;
            @include main-text;

            display: block;
            color: $c-black;
            border-right: 1px solid $c-grey;
            border-bottom: 1px solid $c-grey;
            border-left: 1px solid $c-grey;
            box-sizing: border-box;
            padding: 16px 0 16px 20px;

            &:hover {
                color: $c-red; }

            &.is {
                &-active {
                    background: $c-grey; } }

            &.text {
                &-red {
                    color: $c-red; } } } }

    &-items {
        flex-grow: 1;

        &__head {
            margin: 0 0 24px 0;

            @include tablet-big {
                margin: 0 0 20px 0; } }

        &__btn {
            .btn {
                @include tablet-big {
                    display: block;
                    width: 100%;
                    text-align: center;
                    margin-top: 20px;
                    max-width: 450px;
                    margin-right: auto;
                    margin-left: auto; } } }

        .products {
            &-items {
                &__list {
                    .popular {
                        &-product {
                            &__buy {
                                .btn {
                                    flex: 0 0 52px;

                                    @include tablet-big {
                                        flex: 0 0 36px; } } } } } } } }

        &__list {
            display: flex;
            flex-wrap: wrap;

            .popular {
                &-product {
                    &:hover {
                        .popular {
                            &-product {
                                &__bottom {
                                    background: $c-grey; } } }

                        .btn {
                            &-bucket {
                                &-plus {
                                    background-color: $c-white!important; } } } } } } }

        .popular {
            &-product {
                width: 33.33333%;
                padding: 0;
                border-bottom: 1px solid $c-grey;
                border-right: 1px solid $c-grey;
                // border-left: 1px solid transparent
                box-sizing: border-box;

                @include tablet-big {
                    width: 50%;
                    margin: 0; }

                &:first-child,
                &:nth-child(2),
                &:nth-child(3) {
                    border-top: 1px solid $c-grey; }

                &:nth-child(3n + 1) {
                    border-left: 1px solid $c-grey;

                    @include tablet-big {
                        border-left: none; } }

                &:nth-child(2n + 1) {
                    @include tablet-big {
                        border-left: 1px solid $c-grey; } }

                &:nth-child(3) {
                    @include tablet-big {
                        border-top: none; } }

                &__controls {
                    @include tablet-big {
                        position: absolute;
                        top: 0;
                        right: 0;
                        left: 0;
                        padding: 16px; } }

                &__in {
                    display: flex;
                    flex-direction: column;
                    height: 100%;

                    @include tablet-big {
                        position: relative;
                        padding: 52px 0 0 0;
                        box-sizing: border-box;
                        max-width: 100%; } }

                &__visual {
                    @include tablet-big {
                        position: static; } }

                &__img {
                    @include tablet-big {
                        object-fit: contain; } }

                &__content {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    margin: 0 0 12px 0; }

                &__name {
                    display: block;
                    margin: 0 0 4px 0;

                    @include tablet-big {
                        @include small-head; } }

                &__code {
                    @include sans-light;

                    display: block;
                    margin: 0 0 4px 0;
                    font-size: 16px;
                    line-height: 20px;
                    color: $c-gray-4;

                    @include tablet-big {
                        font-size: 12px;
                        color: $c-black; } }

                &__bottom {
                    @include transition;

                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    box-shadow: none;
                    border-radius: 0px;
                    flex-grow: 1;
                    padding: 16px 24px;

                    .btn {
                        &-bucket {
                            &-plus {
                                @include transition;

                                width: 52px;
                                height: 52px;

                                @include tablet-big {
                                    width: 36px;
                                    height: 36px; } } } } }

                &__desc {
                    @include tablet-big {
                        @include main-text;

                        height: 36px;
                        overflow: hidden; } }

                &__price {
                    @include tablet-big {
                        @include mobile-main-text;

                        margin: 0; } }

                &__buy {
                    @include tablet-big {
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between; } } } } }

    &-filters {
        display: flex;
        justify-content: space-between;

        &__l {
            @include tablet-big {
                @include transition;

                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: red;
                z-index: 1010;
                width: 260px;
                background: $c-white;
                box-shadow: 0px 0px 12px rgba(48, 48, 48, 0.07);
                display: flex;
                flex-direction: column;
                overflow-y: auto;
                padding: 20px 16px;
                box-sizing: border-box;
                pointer-events: none;
                opacity: 0;
                transform: translate3d(-100%, 0, 0); }

            .products {
                &-filter {
                    &__head {
                        &::after {
                            background-image: url('../img/icons/product-filter-arrow-grey.svg'); } } } } }

        &__r {
            @include tablet-big {
                position: relative;
                z-index: 100;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%; }

            .jq {
                &-selectbox {
                    &__dropdown {
                        li {
                            @include tablet-big {
                                padding-left: 0; } } } } } }

        &__bg {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba(21,21,21,.2);
            z-index: 1009;
            pointer-events: none;
            opacity: 0; } }

    &-filter {
        position: relative;
        display: inline-block;
        padding: 0 16px;
        cursor: pointer;
        user-select: none;

        @include tablet-big {
            padding: 0;
            margin: 0 0 16px 0;
            padding: 0 0 16px 0;
            border-bottom: 1px solid $c-gray;
            pointer-events: all; }

        &:last-of-child {
            @include tablet-big {
                border-bottom: none;
                background: $c-red; } }

        &.is {
            &-active {
                .products {
                    &-filter {
                        &__head {
                            &::after {
                                transform: translate(0, -50%) rotate(180deg); } } } } } }

        &-head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 0 24px 0; }

        &__title {
            @include sans-medium;

            position: relative;
            padding: 0 0 0 16px;
            font-size: 12px;
            line-height: 18px;

            &::before {
                content: ' ';
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                left: 0;
                width: 10px;
                height: 10px;
                background: url('../img/icons/filter.svg') center center no-repeat;
                background-size: contain; } }

        &__visible {}

        &__hidden {
            @include tablet-big {
                display: none; } }

        &__head {
            position: relative;
            display: flex;
            align-items: center;
            padding: 0 18px 0 0;
            height: 36px;
            line-height: 36px;

            @include tablet-big {
                height: 24px;
                line-height: 24px; }

            &::after {
                @include transition;

                content: ' ';
                position: absolute;
                top: 50%;
                transform: translate(0, -50%) rotate(0deg);
                right: 0;
                width: 12px;
                height: 12px;
                background: url('../img/icons/product-filter-arrow.svg') center center no-repeat;
                background-size: contain; } }

        &__name {
            @include main-text;

            color: $c-black; }

        &__counter {
            @include sans-light;

            display: none;
            min-width: 14px;
            height: 14px;
            font-size: 8px;
            line-height: 14px;
            border-radius: 50%;
            background: $c-grey;
            color: $c-black;
            margin: 0 0 0 8px;
            text-align: center;

            @include tablet-big {
                min-width: 18px;
                height: 18px;
                line-height: 18px; }

            &.is {
                &-active {
                    display: inline-block; } } }

        &__list {
            @include transition;

            opacity: 0;
            pointer-events: none;
            position: absolute;
            top: 36px;
            left: 0;
            z-index: 1002;
            width: 240px;
            background: $c-white;
            box-shadow: 0px 0px 12px rgba(48, 48, 48, 0.07);
            transform: translate3d(0, 5px, 0);

            @include tablet-big {
                transition: none;
                display: none;
                opacity: 1;
                transform: none;
                position: static;
                box-shadow: none;
                pointer-events: all;
                margin: 8px 0 0 0;
                width: 100%; }

            &.is {
                &-active {
                    opacity: 1;
                    pointer-events: all;
                    transform: translate3d(0, 0px, 0); } }

            &--range {
                width: auto; }

            .inp {
                &-group {
                    margin: 0;
                    padding-right: 16px;
                    padding-left: 16px;

                    @include tablet-big {
                        padding-right: 0;
                        padding-left: 0; }

                    label {
                        display: block;
                        padding-top: 6px;
                        padding-bottom: 6px;
                        padding-left: 28px;

                        &::after,
                        &::before {
                            width: 16px;
                            height: 16px;
                            top: 8.5px; } } } }

            .btn {
                &-link {
                    @include tablet-big {
                        margin-top: 8px;
                        color: $c-black; } } } }

        &__btns {
            &.visible {
                &-mobile {
                    @include tablet-big {
                        flex-direction: column; } } }

            .btn {
                @include tablet-big {
                    display: block;
                    width: 100%; }

                &-link {
                    @include tablet-big {
                        margin: 16px 0 0 0;
                        text-align: left; } } } }

        &-select {
            width: auto;

            .jq {
                &-selectbox {
                    height: 36px;

                    &__select {
                        display: flex;
                        align-items: center;
                        background: $c-white;
                        padding: 0 16px;

                        @include tablet-big {
                            padding: 0; }

                        &-text {
                            padding: 0;
                            white-space: normal;
                            text-overflow: initial;
                            line-height: 36px; } }

                    &__dropdown {
                        background: $c-white;

                        li {
                            line-height: 40px; } }

                    &__trigger {
                        position: relative;
                        width: 12px;
                        height: 36px;
                        right: 0;
                        margin: 0 0 0 8px;

                        &:after {
                            width: 12px;
                            height: 12px;
                            background: url('../img/icons/product-filter-arrow.svg') center center no-repeat;
                            background-size: contain;
                            margin-right: 0; } } } } } }


    &-items {} }

.filter {
    &-active {
        .products-filters {
            &__l {
                @include tablet-big {
                    opacity: 1;
                    pointer-events: all;
                    transform: translate3d(0%, 0, 0); } }

            &__bg {
                opacity: 1;
                pointer-events: all; } } } }

