.authorization {
    &-form {
        max-width: 760px;
        margin: 0 auto;
        box-shadow: 0px 0px 12px rgba(48, 48, 48, 0.07);

        @include tablet-big {
            max-width: 500px; }

        &__head {
            padding: 16px 40px;
            background: $c-dark-grey;
            border-radius: 4px 4px 0 0;

            @include tablet-big {
                padding: 16px 20px; }

            span {
                @include small-head;

                color: $c-white; } }

        &__body {
            padding: 40px;

            @include tablet-big {
                padding: 20px; } }

        &__btns {
            margin: 40px 0 0 0;
            font-size: 0;

            @include tablet-big {
                display: flex;
                flex-direction: column;
                margin: 20px 0 0 0; }

            .btn {
                margin: 0 12px 0 0;

                @include tablet-big {
                    margin: 0 0 20px 0;
                    text-align: center; }

                &:last-child {
                    @include tablet-big {
                        margin: 0; } } } } } }
