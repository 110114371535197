.bucket {
    margin: 0 0 108px 0;

    @include tablet-big {
        margin: 0 0 48px 0; }

    &--inside {
        margin: 0 0 144px 0;

        @include tablet-big {
            margin: 0 0 48px 0; }

        .bucket {
            &__l {
                flex: 0 0 700px;
                margin: 0 100px 0 0;

                @include tablet-big {
                    flex: 0 0 100%;
                    margin: 0; } }

            &__r {
                @include tablet-big {
                    width: 100%;
                    margin: 0 0 32px 0; } }

            &__in {
                @include tablet-big {
                    display: flex;
                    flex-direction: column-reverse; } }

            &-headline {
                @include tablet-big {
                    margin: 0 0 20px 0; } } } }

    &-headline {
        @include big-head;

        display: block;
        margin: 0 0 40px 0;

        @include tablet-big {
            @include mobile-head;

            margin: 0 0 12px 0; }

        &--finish {
            position: relative;
            padding: 0 0 0 64px;

            @include tablet-big {
                margin: 0 0 32px 0;
                padding: 0 0 0 44px; }

            &::before {
                content: ' ';
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                left: 0;
                width: 44px;
                height: 44px;
                background: url('../img/icons/checkmark-black.svg') center center no-repeat;
                background-size: contain;

                @include tablet-big {
                    width: 32px;
                    height: 32px; } } } }

    &__in {
        display: flex;

        @include tablet-big {
            flex-direction: column; }

        &--empty {
            @include tablet-big {
                flex-direction: column-reverse; }

            .bucket {
                &__l {
                    @include tablet-big {
                        margin: 0; } } } } }

    &__l {
        flex: 0 0 800px;

        @include tablet-big {
            flex: 0 0 100%;
            margin: 0 0 28px 0; } }

    &-items {
        &__head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 60px 0 0;
            margin: 0 0 24px 0;

            @include tablet-big {
                margin: 0 0 26px 0;
                padding: 0; } }

        &__title {
            @include tablet-big {
                @include main-text;

                color: $c-gray-4; } }

        &__clear {
            @include main-text;

            position: relative;
            padding: 0 0 0 16px;
            color: $c-gray-4;

            &:hover {
                color: $c-gray-4; }

            &::after {
                content: ' ';
                width: 8px;
                height: 8px;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translate(0, -50%);
                background: url('../img/icons/clear.svg') center center no-repeat;
                background-size: contain; } } }

    &-item {
        display: flex;
        padding: 8px 60px 8px 0;
        border-bottom: 1px solid $c-grey;

        @include tablet-big {
            position: relative;
            flex-direction: column;
            margin: 0 0 16px 0;
            padding: 0 0 16px 0; }

        & > .btn-close {
            @include tablet-big {
                position: absolute;
                top: 0;
                right: 0; } }

        &__l {
            @include link;

            display: flex;
            align-items: center;
            flex: 0 0 340px;
            margin: 0 36px 0 0;

            @include tablet-big {
                flex: 0 0 100%;
                margin: 0 0 16px 0; } }

        &__visual {
            width: 100px;
            height: 100px;
            margin: 0 20px 0 0; }

        &__img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover; }

        &__name {
            @include small-head;

            @include tablet-big {
                @include mobile-small-head; } }

        &__content {
            @include main-text;

            @include tablet-big {
                @include mobile-main-text;

                margin: 0 0 8px 0; } }

        &__vendor {
            &-code {
                color: $c-gray-4; } }

        &__r {
            display: flex;
            flex: 1 1 auto;
            justify-content: flex-end; }

        &__details {
            display: flex;
            align-items: center;
            width: 100%;
            position: relative;

            .btn {
                &-close {
                    position: absolute;
                    top: 0;
                    right: 0; } } }

        .product {
            &-counter {
                margin: 0 20px;

                @include tablet-big {
                    margin: 0 20px 0 0; }

                &__control {
                    width: 40px;
                    height: 40px;

                    @include tablet-big {
                        width: 36px;
                        height: 36px; } }

                & > input {
                    width: 40px;
                    height: 40px;

                    @include tablet-big {
                        width: 36px;
                        height: 36px; } } } }

        &__price {
            @include small-head;

            display: inline-block;
            min-width: 100px;

            @include tablet-big {
                @include mobile-main-text;

                display: flex;
                align-items: center;
                order: 2;
                color: $c-gray-4; }

            i {
                font-style: normal; }

            &--total {
                text-align: right;

                @include tablet-big {
                    @include mobile-small-head;

                    justify-content: flex-end;
                    color: $c-black; } } } }

    &__r {
        flex-grow: 1; }

    &-receipt {
        background: $c-grey;
        padding: 40px;
        border-radius: 4px;

        @include tablet-big {
            padding: 28px; }

        &__title {
            @include small-head;

            display: flex;
            margin: 0 0 20px 0;
            color: $c-black;
            justify-content: space-between;
            align-items: center;

            @include tablet-big {
                margin: 0 0 16px 0; } }

        &__items {
            margin: 0 0 28px 0;

            @include tablet-big {
                margin: 0 0 24px 0; } }

        &__item {
            display: flex;
            margin: 0 0 12px 0;

            &:last-of-type {
                margin: 0; }

            span {
                @include main-text;

                &:first-of-type {
                    color: $c-gray-4;
                    flex: 0 0 100px;
                    margin: 0 40px 0 0;

                    @include tablet-big {
                        box-sizing: border-box; } }

                &:nth-of-type(2) {
                    color: $c-black;
                    flex-grow: 1; } } }

        &__promocode {
            display: block;
            width: 100%;
            height: 40px;
            line-height: 40px;
            border: none;
            outline: none;
            box-shadow: none;
            background: transparent;
            padding: 0;
            margin: 0 0 28px 0;
            border-bottom: 1px solid $c-gray-4;

            @include placeholder-all {
                @include main-text;

                font-size: 16px;
                color: $c-gray-4; }

            @include tablet-big {
                height: 30px;
                line-height: 30px;
                margin: 0 0 24px 0; } }

        &__total {
            display: flex;

            span {
                @include main-text;

                @include tablet-big {
                    @include mobile-main-text; }

                &:first-of-type {
                    color: $c-black;
                    flex: 0 0 100px;
                    margin: 0 40px 0 0;

                    @include tablet-big {
                        @include mobile-main-text; } }

                &:nth-of-type(2) {
                    @include small-head;

                    flex-grow: 1;
                    color: $c-black; } } }

        &__btns {
            margin: 28px 0 0 0;

            @include tablet-big {
                margin: 24px 0 0 0;
                max-width: 400px; }

            .btn {
                margin: 0 0 12px 0;

                &:last-of-type {
                    margin: 0; } } } }

    &-empty {
        margin: 0 0 108px 0;

        &__desc {
            max-width: 404px;
            margin: 0 0 36px 0;

            @include tablet-big {
                margin: 0 0 24px 0; }

            p {
                @include main-text;

                color: $c-black; } }

        &__img {
            display: block;

            @include tablet-big {
                margin: 0 0 25px 0; }

            img {
                display: block;
                width: 100%;

                @include tablet {
                    width: 300px; }

                @include mobile {
                    width: 150px;
                    margin: auto; } } } }

    &-step {
        padding: 0 0 40px 0;
        border-bottom: 1px solid $c-gray-5;
        margin: 0 0 40px 0;

        @include tablet-big {
            margin: 0 0 32px 0;
            padding: 0 0 32px 0; }

        &:last-of-type {
            border-bottom: none;
            margin: 0;
            padding: 0; }

        &--2 {
            .bucket {
                &-step {
                    &__head {
                        .bucket {
                            &-step {
                                &__title {
                                    display: none;

                                    &.is-active {
                                        display: block; } } } } }

                    &__mini {
                        max-width: 500px; } } } }

        &.is {
            &-hidden {
                display: none; }

            &-passed {
                .bucket {
                    &-step {
                        &__counter {
                            background: $c-white;
                            color: $c-black; } } } }

            &-inactive {
                .bucket {
                    &-step {
                        &__counter {
                            @include main-text;

                            line-height: 42px;
                            color: $c-gray-4;
                            border-color: $c-gray-4;
                            background: transparent;

                            @include tablet-big {
                                line-height: 34px; } }

                        &__title {
                            color: $c-gray-4; }

                        &__step {
                            display: none; } } } } }

        &__step {
            display: none;

            &.is {
                &-active {
                    display: block; } }

            // &:last-of-type
            //     margin: 0

            + .bucket {
                &-step {
                    &__step {
                        margin-top: 28px; } } } }

        &__head {
            position: relative;
            display: flex;
            align-items: center;

            + div {
                margin-top: 20px; }

            .btn {
                &-link {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translate(0, -50%);
                    opacity: 0;
                    pointer-events: none;

                    &.is {
                        &-active {
                            pointer-events: all;
                            opacity: 1; } } } } }

        &__counter {
            @include transition;
            @include main-text;

            width: 44px;
            height: 44px;
            line-height: 42px;
            text-align: center;
            margin: 0 20px 0 0;
            color: $c-white;
            background: $c-black;
            border: 1px solid $c-black;
            border-radius: 50%;
            box-sizing: border-box;

            @include tablet-big {
                width: 36px;
                height: 36px;
                line-height: 34px;
                margin: 0 16px 0 0; } }

        &__title {
            @include small-head; }

        &__step {
            padding: 0 0 0 64px;
            box-sizing: border-box;

            @include tablet-big {
                padding: 0; } }

        &__person {
            display: flex;
            margin: 0 0 28px 0;

            @include tablet-big {
                flex-direction: column;
                margin: 0 0 24px 0; }

            .inp {
                &-group {
                    margin: 0 20px 0 0;

                    @include tablet-big {
                        margin: 0 0 20px 0; }

                    &:last-of-type {
                        @include tablet-big {
                            margin: 0; } } } } }

        &__address {
            margin: 0 0 28px 0;

            @include tablet-big {
                margin: 0 0 24px 0; }

            &-title {
                @include main-text;

                display: block;
                margin: 0 0 16px 0;
                color: $c-gray-4; }

            &.is {
                &-invalid {
                    .bucket {
                        &-step {
                            &__address {
                                &-title {
                                    color: $c-red; } } } }

                    .select2-container--default .select2-selection--single {
                        border-color: $c-red; } } } }

        &__delivery {
            display: flex;
            margin: 0 0 28px 0;

            @include tablet-big {
                margin: 0 -16px 24px -16px;
                padding: 0 0 0 16px;
                flex-wrap: nowrap;
                overflow-x: auto;
                white-space: nowrap;

                &::-webkit-scrollbar,
                &::-webkit-scrollbar-track,
                &::-webkit-scrollbar-thumb {
                    display: none;
                    background: transparent;
                    opacity: 0;
                    visibility: hidden; } }

            .inp {
                &-group {
                    &--delivery {
                        width: 296px;
                        margin: 0 20px 0 0;

                        @include tablet-big {
                            width: 193px;
                            min-width: 193px; }

                        label {
                            display: block;
                            height: 100%;
                            box-sizing: border-box; } } } } }

        &__fields {
            max-width: 400px;
            display: none;

            &.is {
                &-active {
                    display: block; } } }

        &__payments {
            .inp {
                &-group {
                    margin: 0 0 20px 0;

                    &:last-of-type {
                        margin: 0; } } } }

        &__btn {
            .btn {
                min-width: 280px;
                box-sizing: border-box;
                text-align: center;

                @include tablet-big {
                    min-width: 100%; } } }

        &__mini {
            // display: none
            position: relative;
            max-width: 384px;
            flex-direction: column;
            align-items: flex-start;
            margin: 0 0 28px 0;
            padding: 0 100px 0 64px;
            box-sizing: border-box;

            @include tablet-big {
                max-width: 100%;
                padding-left: 0;
                margin-bottom: 0; }

            & + .bucket {
                &-step {
                    &__step {
                        margin-top: 20px; } } }

            &.is {
                &-hidden {
                    display: none; } }

            .btn {
                &-link {
                    height: 28px;
                    line-height: 28px;
                    position: absolute;
                    top: 0;
                    right: 0;

                    &.is {
                        &-hidden {
                            opacity: 0;
                            pointer-events: none; } } } }

            &-txt,
            span {
                @include main-text;

                display: block;
                color: $c-black;
                margin: 0; }

            &-title {
                &.is {
                    &-hidden {
                        display: none; } } }

            &-adress,
            &-time,
            &-pickup {
                &.is {
                    &-hidden {
                        display: none; } } } } }

    &-finish {
        &__content {
            margin: 0 0 40px 0;

            @include tablet-big {
                margin: 0 0 28px 0; }

            &:last-of-type {
                margin: 0; } }

        &__title {
            font-size: 0;

            @include tablet-big {
                display: flex;
                flex-wrap: wrap;
                margin: 0 0 12px 0; }

            span {
                @include small-head;

                display: inline-block;
                color: $c-black;

                + span {
                    margin: 0 0 0 12px; } } }

        &__content {
            p {
                @include main-text;

                color: $c-black;

                a {
                    @include link;
                    @include main-text;

                    color: $c-black; } } }

        &__footer {
            .btn {
                margin: 0 12px 0 0;

                @include tablet-big {
                    order: 2;
                    margin: 0 0 20px 0; }

                &-subscribe {
                    @include tablet-big {
                        width: 100%;
                        order: 1; } }

                &-prev {
                    @include tablet-big {
                        margin: 0; } }

                &-fb,
                &-instagram {
                    @include tablet-big {
                        width: 52px;
                        height: 52px;
                        margin: 0 12px 0 0; } } } }

        &__btns {
            display: flex;
            margin: 0 0 40px 0;

            @include tablet-big {
                flex-wrap: wrap;
                margin: 0 0 28px 0; } } } }

[data-step="valid"] {
    .bucket {
        &-step {
            &__mini {
                display: none; } } } }

[data-step="valid-mini"] {
    .bucket {
        &-step {
            &__mini {
                display: flex; }

            &__big {
                display: none; } } } }

.select2 {
    + .bucket {
        &-step {
            &__btn {
                margin-top: 28px; } } } }
