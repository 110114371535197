.delivery {
    &-terms {
        position: relative;
        display: block;
        border: 2px solid $c-grey;
        padding: 24px;
        border-radius: 8px;
        cursor: pointer;
        height: 100%;
        box-sizing: border-box;

        @include tablet-big {
            padding: 20px; }

        label {
            cursor: pointer;
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 2; }

        &__head {
            display: flex;
            align-items: center;
            margin: 0 0 28px 0;

            @include tablet-big {
                flex-direction: column;
                align-items: flex-start;
                margin: 0 0 8px 0; } }

        &__icon {
            width: 36px;
            height: 36px;
            margin: 0 12px 0 0;

            @include tablet-big {
                margin: 0 0 8px 0; }

            path {
                @include transition;

                fill: $c-gray-4; } }

        &__title {
            @include transition;
            @include small-head;

            color: $c-gray-4; }

        &__content {
            margin: 0 0 28px 0;

            @include tablet-big {
                margin: 0 0 8px 0; }

            p {
                @include transition;

                white-space: normal;
                user-select: none;
                color: $c-gray-4;

                @include tablet-big {
                    margin: 0 0 8px 0; }

                &:last-of-type {
                    @include tablet-big {
                        margin: 0; } } } }

        &__btn {
            .btn {
                color: $c-gray-4; } }

        &__datepicker {
            position: relative;
            z-index: 2;
            margin: 0 0 8px 0;

            .datepicker {
                color: $c-gray-4;

                @include placeholder-all {
                    color: $c-gray-4; } } }

        &__time {
            display: block;
            line-height: 1;
            font-size: 0;

            .jq {
                &-selectbox {
                    &__select {
                        &-text {
                            color: $c-gray-4; } }

                    &__trigger {
                        &::after {
                            background: url('../img/icons/arrow-dropdown-gray.svg') center center no-repeat;
                            background-size: contain; } } } } } } }

.inp {
    &-group {
        &--delivery {
            position: relative;

            & > input {
                position: absolute;
                // visibility: hidden
                opacity: 0;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 100%;
                z-index: -1;

                &:checked {
                    + .delivery-terms {
                        border-color: $c-dark-grey;

                        .datepicker {
                            &-wrapper {
                                &::after {
                                    background: $c-gray url('../img/icons/calendar-black.svg') center center no-repeat;
                                    background-size: 12px auto; } } }

                        .delivery {
                            &-terms {
                                &__icon {
                                    path {
                                        fill: $c-dark-grey; } }

                                &__title {
                                    color: $c-black; }

                                &__content {
                                    margin: 0 0 28px 0;

                                    @include tablet-big {
                                        margin: 0 0 8px 0; }

                                    p {
                                        color: $c-black; } }

                                &__btn {
                                    .btn {
                                        color: $c-dark-grey;

                                        &:hover {
                                            color: $c-white; } } }

                                &__datepicker {
                                    .datepicker {
                                        color: $c-black;

                                        @include placeholder-all {
                                            color: $c-black; } } }

                                &__time {
                                    .jq {
                                        &-selectbox {
                                            &__select {
                                                &-text {
                                                    color: $c-black; } }

                                            &__trigger {
                                                &::after {
                                                    background: url('../img/icons/arrow-dropdown-black.svg') center center no-repeat;
                                                    background-size: contain; } } } } } } } } } }

            // label
            //     display: block
            //     border: 2px solid $c-grey
            //     padding: 24px
            //     border-radius: 8px
 } } }            //     cursor: pointer
