.cta {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $c-grey;
    padding: 40px;
    margin: 0 0 60px 0;

    @include tablet-big {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 48px 16px;
        margin: 0 -16px 48px -16px; }

    &__description {
        max-width: 80%;

        @include tablet-big {
            max-width: 100%;
            margin: 0 0 28px 0; } }

    &__title {
        @include small-head;

        display: block;
        margin: 0 0 12px 0; }

    p {
        @include main-text; } }
