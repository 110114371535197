.dealers {
    &-items {
        display: flex;
        flex-wrap: wrap; }

    &-item {
        width: 360px;
        margin: 0 40px 0 0;

        @include mobile {
            width: 100%;
            margin: 0 0 32px 0; }

        &__visual {
            position: relative;
            padding: 0 0 67% 0;
            margin: 0 0 24px 0;
            border-radius: 4px;
            overflow: hidden;

            @include mobile {
                margin: 0 0 20px 0; }

            img {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover; } }

        &__content {
            @include link;

            display: flex;
            align-items: center;

            @include mobile {
                justify-content: space-between; } }

        &__title {
            @include small-head;
            @include link;

            margin: 0 20px 0 0;
            text-decoration: none;
            color: $c-black; }

        &__files {
            display: flex;
            align-items: center;
            margin: 0 0 0 -20px;

            li {
                padding: 0 20px;
                border-right: 1px solid $c-gray-4;

                &:last-of-type {
                    border-right: none;

                    @include mobile {
                        padding-right: 0; } }

                a {
                    @include sans-light;

                    color: $c-black;
                    text-decoration: underline; } } }

        .btn {
            &-arrow {
                width: 28px;
                height: 28px;
                background-color: $c-grey;
                background-image: url('../img/icons/arrow-next-black.svg');
                border-radius: 50%; } } } }
