.contacts {
    &-item {
        margin: 0 0 60px 0;

        @include tablet-big {
            margin: 0 0 36px 0; }

        p {
            @include main-text;

            b {
                @include sans-semibold; }

            a {
                @include link;
                @include transition;

                &:hover {
                    color: $c-red; } } }

        &--map {
            border-radius: 8px;
            overflow: hidden;

            iframe {
                display: block;
                width: 100%;
                height: 408px;

                @include tablet-big {
                    height: 158px; } } }

        &__title {
            display: block;
            margin: 0 0 20px 0;

            @include tablet-big {
                margin: 0 0 12px 0; }

            .head {
                @include tablet-big {
                    @include mobile-small-head; } } } } }
