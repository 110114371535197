.clients {
    margin: 0 0 92px 0;

    @include tablet-big {
        margin: 0 0 36px 0; }

    .headline {
        margin: 0 0 32.5px;

        @include tablet-big {
            margin: 0 0 16.5px 0; } }

    &__items {
        margin: 0 -7.5px;

        @include tablet-big {
            margin: 0 -16px; }

        .slick {
            &-track {
                padding: 7.5px 0 12px 0; }

            &-list {
                @include tablet-big {
                    padding: 0 0 0 16px; } } } }

    &-item {
        position: relative;
        height: 120px;
        margin: 0 7.5px;
        box-shadow: 0px 4px 7.5px rgba(48, 48, 48, 0.07);
        border-radius: 4px;

        @include tablet-big {
            width: 112px;
            height: 60px;
            margin: 0 16px 0 0; }

        &:hover {
            .clients {
                &-item {
                    &__img {
                        &--original {
                            opacity: 0; }

                        &--fill {
                            opacity: 1; } } } } }

        &__in {}


        &__img {
            @include transition;

            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;

            &--fill {
                opacity: 0; } } } }
