.datepicker {
    @include main-text;

    display: block;
    width: 100%;
    height: 44px;
    line-height: 44px;
    box-sizing: border-box;
    padding: 0 16px;
    margin: 0;
    border: none;
    box-shadow: none;
    border-radius: 4px;
    background: $c-gray;

    @include tablet-big {
        height: 34px;
        line-height: 34px; }

    @include placeholder-all {
        @include main-text;

        line-height: 44px;
        color: $c-black; }

    &-wrapper {
        position: relative;

        &::after {
            content: ' ';
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            right: 0;
            width: 44px;
            height: 44px;
            background: $c-gray url('../img/icons/calendar-gray.svg') center center no-repeat;
            background-size: 12px auto;

            @include tablet-big {
                width: 34px;
                height: 34px; } } } }


.ui {
    &-datepicker {
        z-index: 101!important; } }

.ui-corner-all, .ui-corner-bl, .ui-corner-bottom, .ui-corner-left {
    border-radius: 0px; }

.ui-datepicker .ui-datepicker-title,
.ui-datepicker th,
.ui-button, .ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, html .ui-button.ui-state-disabled:active, html .ui-button.ui-state-disabled:hover {
    @include sans-regular; }

.ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight {
    background: $c-gray-5;
    border-color: $c-gray-5;
    color: $c-black;
    text-decoration: none; }

.ui-button.ui-state-active:hover, .ui-button:active, .ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active {
    background: $c-dark-grey;
    border-color: $c-dark-grey;
    color: $c-white; }

.ui-icon, .ui-widget-content .ui-icon, .ui-widget-header .ui-icon {
    background: none; }

.ui-datepicker .ui-datepicker-next, .ui-datepicker .ui-datepicker-prev {
    cursor: pointer;
    background: url('../img/icons/arrow-prev-gray.svg') center center no-repeat;
    background-size: contain; }

.ui-datepicker .ui-datepicker-next, .ui-datepicker .ui-datepicker-next {
    cursor: pointer;
    background: url('../img/icons/arrow-next-gray.svg') center center no-repeat;
    background-size: contain; }
