.category {
    margin: 0 0 100px 0;

    @include tablet-big {
        margin: 0 0 48px 0; }

    &__head {
        margin: 0 0 40px 0;

        @include tablet-big {
            margin: 0 0 12px 0; } }

    &__headline {
        .headline {
            margin: 0 0 28px 0;

            @include tablet-big {
                margin: 0 0 12px 0; } } }

    &__items {
        display: flex;
        flex-wrap: wrap;
        border-top: 1px solid $c-grey; }

    &-item {
        @include link;

        position: relative;
        flex: 0 0 20%;
        padding-bottom: 20%;
        border-bottom: 1px solid $c-grey;
        border-right: 1px solid $c-grey;
        border-left: 1px solid transparent;
        box-sizing: border-box;

        @include tablet-big {
            flex: 0 0 33.33333%;
            padding-bottom: 16px;
            padding-top: 16px; }

        &:hover {
            .category {
                &-item {
                    &__visual {
                        transform: translate3d(0,0,0) scale(1.1);

                        @include tablet-big {
                            transform: translate3d(0,0,0) scale(1); } } } } }

        &:nth-child(5n + 1) {
            border-left: 1px solid $c-grey;

            @include tablet-big {
                border-left: none; } }

        &:nth-child(3n + 1) {
            @include tablet-big {
                border-left: 1px solid $c-grey; } }

        &__in {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            @include tablet-big {
                position: static; } }

        &__visual {
            @include transition;

            transform: translate3d(0,0,0) scale(1);
            width: 152px;
            height: 152px;
            margin: 0 auto;

            @include tablet-big {
                width: 52px;
                height: 52px; } }

        &__img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover; }

        &__name {
            @include main-text;

            display: block;
            text-align: center;
            color: $c-black;

            @include tablet-big {
                height: 36px; }

            @include mobile-small {
                font-size: 10px; }

            &--red {
                color: $c-red; } } }

    &__btn {
        margin: 40px 0 0 0;
        text-align: center;

        @include tablet-big {
            margin: 20px 0 0 0; }

        .btn {
            @include mobile {
                width: 100%;
                max-width: 450px;
                margin-right: auto;
                margin-left: auto; } } } }
