.popular {
    &-products {
        margin: 0 0 100px 0;

        @include tablet-big {
            margin: 0 0 48px 0; }

        &--4 {
            margin: 0 0 60px 0;

            .popular {
                &-product {
                    &__controls {
                        @include tablet-big {
                            display: none; } }

                    &__bottom {
                        @include tablet-big {
                            margin-top: 4px;
                            padding: 0 16px 16px 16px; } }

                    &__buy {
                        @include tablet-big {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-between; } }

                    &__price {
                        @include tablet-big {
                            margin: 0; } } }

                &-products {
                    &__items {
                        padding: 0;
                        border-top: 1px solid $c-grey;
                        border-bottom: 1px solid $c-grey;
                        border-right: 1px solid $c-grey;

                        .slick {
                            &-track {
                                padding-bottom: 0; } }

                        .popular {
                            &-product {
                                padding: 0;
                                border-left: 1px solid $c-grey;
                                box-sizing: border-box;

                                &__bottom {
                                    box-shadow: none; }

                                &__price {
                                    @include small-head; } } } } } } }

        &__items {
            width: 100%;
            padding: 0 52px;
            box-sizing: border-box;

            @include tablet-big {
                width: auto;
                padding: 0;
                margin: 0 -16px; }

            .slick {
                &-track {
                    display: flex;
                    padding: 0 0 12px 0; }

                &-list {
                    @include tablet-big {
                        padding: 0 0 0 16px; } }

                &-arrow {
                    padding: 0;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    cursor: pointer;
                    width: 36px;
                    height: 100%;
                    background: transparent;
                    text-indent: -99999px;
                    border: none;
                    outline: none;
                    box-shadow: none;
                    z-index: 10;

                    &:hover {
                        &::after {
                            border-color: $c-dark-grey; } } }

                &-prev {
                    position: absolute;
                    left: 0;

                    &::after {
                        content: ' ';
                        position: absolute;
                        top: 50%;
                        transform: translate(0, -50%);
                        left: 0;

                        width: 100%;
                        height: 36px;
                        box-sizing: border-box;
                        border: 1px solid $c-gray-4;
                        border-radius: 50%;
                        background: url('../img/icons/arrow-prev.svg') center center no-repeat;
                        background-size: contain; }

                    &:hover {
                        &::after {
                            background: $c-dark-grey url('../img/icons/arrow-prev-white.svg') center center no-repeat;
                            background-size: contain; } } }


                &-next {
                    position: absolute;
                    right: 0;

                    &::after {
                        content: ' ';
                        position: absolute;
                        top: 50%;
                        transform: translate(0, -50%);
                        right: 0;

                        width: 100%;
                        height: 36px;
                        box-sizing: border-box;
                        border: 1px solid $c-gray-4;
                        border-radius: 50%;
                        background: url('../img/icons/arrow-next.svg') center center no-repeat;
                        background-size: contain; }

                    &:hover {
                        &::after {
                            background: $c-dark-grey url('../img/icons/arrow-next-white.svg') center center no-repeat;
                            background-size: contain; } } } } } }

    &-product {
        display: flex!important;
        flex-direction: column;
        padding: 0 16px;
        height: auto;

        outline: none;

        &:hover,
        &:focus,
        &:hover:focus {
            border: none;
            outline: none;
            -webkit-tap-highlight-color: transparent; }

        @include tablet-big {
            width: 160px;
            padding: 0;
            margin: 0 16px 0 0; }

        &__in {
            @include link;

            display: flex;
            flex-direction: column;
            max-width: 320px;
            height: 100%;
            outline: none;

            &:hover,
            &:focus,
            &:hover:focus {
                border: none;
                outline: none;
                -webkit-tap-highlight-color: transparent; }

            &:hover {
                .popular {
                    &-product {
                        &__controls {
                            opacity: 1;
                            pointer-events: all; } } } } }

        &__visual {
            position: relative;
            height: 180px;
            background: $c-white;

            @include tablet-big {
                height: 100px; } }

        &__img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover; }

        &__controls {
            @include transition;

            position: absolute;
            top: 32px;
            left: 32px;
            display: flex;
            flex-direction: column;
            opacity: 0;
            z-index: 10;

            @include tablet-big {
                width: 100%;
                position: static;
                opacity: 1;
                flex-direction: row;
                justify-content: space-between;
                padding: 20px 20px 12px 20px;
                box-sizing: border-box; }

            .btn {
                @include transition;

                margin: 0 0 8px 0;
                border-radius: 50%;
                background: transparent;

                @include tablet-big {
                    margin: 0;
                    border: 1px solid $c-grey;
                    box-sizing: border-box; }

                &:hover {
                    background: $c-grey; }

                &:last-of-type {
                    margin: 0; } } }

        &__bottom {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex-grow: 1;
            border-radius: 0 0 4px 4px;
            padding: 32px 24px 40px 32px;
            box-shadow: 0px 0px 12px rgba(48, 48, 48, 0.07);

            @include tablet-big {
                padding: 64px 20px 20px 20px; } }

        &__content {
            padding: 0 44px 0 0;
            margin: 0 0 8px 0;

            @include tablet-big {
                padding: 0;
                margin: 0 0 12px 0; } }

        &__name {
            @include small-head;

            display: block;
            min-height: 50px;

            @include tablet-big {
                display: block;
                margin: 0 0 4px 0;
                min-height: 18px; } }

        &__desc {
            @include sans-light;

            font-size: 16px;
            line-height: 20px;
            min-height: 40px;

            @include tablet-big {
                @include mobile-main-text;

                min-height: 36px; } }

        &__buy {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include tablet-big {
                flex-direction: column;
                align-items: flex-start; }

            .btn {
                &-bucket {
                    &-plus {
                        flex: 0 0 60px;

                        @include tablet-big {
                            flex: 0 0 48px; } } } } }

        &__price {
            @include main-text;

            flex-grow: 1;

            @include tablet-big {
                @include mobile-small-head;

                display: block;
                margin: 0 0 12px 0; } } } }

.product {
    &-item {
        padding: 0;
        outline: none;

        &:hover,
        &:focus,
        &:hover:focus {
            border: none;
            outline: none;
            -webkit-tap-highlight-color: transparent; }

        // +tablet-big
        //     width: 160px
        //     padding: 0
        //     margin: 0 16px 0 0

        &__in {
            @include link;

            display: flex;
            flex-direction: column;
            max-width: 290px;
            outline: none;
            // border-left: 1px solid $c-grey

            &:hover,
            &:focus,
            &:hover:focus {
                // border: none
                outline: none;
                -webkit-tap-highlight-color: transparent; }

            &:hover {
                .popular {
                    &-product {
                        &__controls {
                            opacity: 1;
                            pointer-events: all; } } } } }

        &__visual {
            position: relative;
            height: 180px;

            @include tablet-big {
                height: 100px; } }

        &__img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover; }

        &__controls {
            @include transition;

            position: absolute;
            top: 32px;
            left: 32px;
            display: flex;
            flex-direction: column;
            opacity: 0;
            z-index: 10;

            @include tablet-big {
                width: 100%;
                position: static;
                opacity: 1;
                flex-direction: row;
                justify-content: space-between;
                padding: 20px 20px 12px 20px;
                box-sizing: border-box; }

            .btn {
                @include transition;

                margin: 0 0 8px 0;
                border-radius: 50%;
                background: transparent;

                @include tablet-big {
                    margin: 0;
                    border: 1px solid $c-grey;
                    box-sizing: border-box; }

                &:hover {
                    background: $c-grey; }

                &:last-of-type {
                    margin: 0; } } }

        &__bottom {
            border-radius: 0;
            padding: 40px 32px;

            @include tablet-big {
                padding: 64px 20px 20px 20px; } }

        &__content {
            padding: 0;
            margin: 0 0 8px 0;

            @include tablet-big {
                padding: 0;
                margin: 0 0 12px 0; } }

        &__name {
            @include small-head;

            display: block;
            margin: 0 0 4px 0; }

        &__desc {
            @include sans-light;

            font-size: 16px;
            line-height: 20px;
            min-height: 40px;

            @include tablet-big {
                @include mobile-main-text;

                min-height: 36px; } }

        &__buy {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include tablet-big {
                flex-direction: column;
                align-items: flex-start; } }

        &__price {
            @include small-head;

            @include tablet-big {
                @include mobile-small-head;

                display: block;
                margin: 0 0 12px 0; } }

        &__characteristics {
            max-width: 290px;
            padding: 8px 32px;
            box-sizing: border-box;
            border-bottom: 1px solid $c-grey;

            @include tablet-big {
                padding: 8px 16px; } }

        &__title {
            @include main-text;

            display: block;
            margin: 0 0 8px 0;
            color: $c-gray-4; }

        &__value {
            @include main-text;

            color: $c-black; } } }
