.compare {
    &-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 32px 0;

        @include tablet-big {
            margin: 0 0 20px 0; }

        &__difference {
            display: flex; } }

    &__items {
        position: relative;
        display: flex;
        flex-wrap: wrap;

        &.arrow {
            &-disabled {
                .slick {
                    &-arrow {
                        display: none!important; } } } }

        &::after {
            content: ' ';
            position: absolute;
            top: 0;
            left: 0;
            width: 1px;
            height: 100%;
            background: $c-grey; }

        &:hover {
            .slick {
                &-arrow {
                    opacity: 1;
                    pointer-events: all; } } }

        .slick {
            &-track {
                margin-left: 0; }

            &-list {
                width: 100%;

                // +tablet-big
 }                //     padding: 0 0 0 16px

            &-arrow {
                @include transition;

                padding: 0;
                position: absolute;
                top: 50px;
                bottom: 50px;
                cursor: pointer;
                width: 36px;
                height: 100%;
                background: transparent;
                text-indent: -99999px;
                border: none;
                outline: none;
                box-shadow: none;
                z-index: 1001;
                opacity: 0;
                pointer-events: none;

                @include tablet-big {
                    top: 104px;
                    width: 28px;
                    height: 28px;
                    bottom: initial; }

                &:hover {
                    &::after {
                        border-color: $c-dark-grey; } } }

            &-prev {
                position: absolute;
                left: 1px;

                @include tablet-big {
                    left: -14px; }

                &::after {
                    content: ' ';
                    position: absolute;
                    top: 82px;
                    transform: none;
                    left: 0;

                    width: 100%;
                    height: 36px;
                    border: 1px solid $c-gray-4;
                    border-radius: 50%;
                    background: $c-white url('../img/icons/arrow-prev.svg') center center no-repeat;
                    background-size: contain;

                    @include tablet-big {
                        top: 0;
                        width: 28px;
                        height: 28px;
                        background: $c-grey url('../img/icons/arrow-prev-black.svg') center center no-repeat;
                        background-size: contain;
                        border-color: $c-grey;
                        bottom: initial; } }

                &:hover {
                    &::after {
                        background: $c-dark-grey url('../img/icons/arrow-prev-white.svg') center center no-repeat;
                        background-size: contain;

                        @include tablet-big {
                            background: $c-grey url('../img/icons/arrow-prev-black.svg') center center no-repeat;
                            background-size: contain;
                            border-color: $c-grey; } } } }

            &-next {
                position: absolute;
                right: 1px;

                @include tablet-big {
                    right: -14px; }

                &::after {
                    content: ' ';
                    position: absolute;
                    top: 82px;
                    transform: none;
                    right: 0;

                    width: 100%;
                    height: 36px;
                    border: 1px solid $c-gray-4;
                    border-radius: 50%;
                    background: $c-white url('../img/icons/arrow-next.svg') center center no-repeat;
                    background-size: contain;

                    @include tablet-big {
                        top: 0;
                        width: 28px;
                        height: 28px;
                        background: $c-grey url('../img/icons/arrow-next-black.svg') center center no-repeat;
                        background-size: contain;
                        border-color: $c-grey; } }


                &:hover {
                    &::after {
                        background: $c-dark-grey url('../img/icons/arrow-next-white.svg') center center no-repeat;
                        background-size: contain;

                        @include tablet-big {
                            background: $c-grey url('../img/icons/arrow-next-black.svg') center center no-repeat;
                            background-size: contain;
                            border-color: $c-grey; } } } } } }

    &__item {
        position: relative;
        max-width: 290px;
        box-sizing: border-box;
        margin: 0;
        padding: 0;

        &::after {
            content: ' ';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 1px;
            height: 100%;
            background: $c-grey;

            @include tablet-big {
                right: 1px; } }

        &:first-of-type {
 }            // border-left: 1px solid $c-grey

        .product {
            &-item {
                &__in {
                    border-top: 1px solid $c-grey;
                    border-bottom: 1px solid $c-grey; }

                &__top {
                    @include tablet-big {
                        padding: 16px 16px 0 16px; }

                    .btn {
                        &-close {
                            position: absolute;
                            top: 0;
                            right: 0;
                            width: 44px;
                            height: 44px;
                            background-size: 12px; } } }

                &__bottom {
                    @include tablet-big {
                        padding: 4px 16px 16px 16px; } }

                &__desc {
                    @include tablet-big {
                        height: 36px;
                        overflow: hidden; } }

                &__buy {
                    @include tablet-big {
                        flex-direction: row;
                        align-items: center; }

                    .btn {
                        &-bucket {
                            &-plus {
                                @include tablet-big {
                                    width: 36px;
                                    height: 36px; } } } } }

                &__price {
                    @include tablet-big {
                        @include mobile-main-text;

                        margin: 0; } } } } }
    &-empty {
        display: none;

        &.is {
            &-active {
                display: block; } }

        &__icon {
            vertical-align: middle;
            margin: 0 8px;

            @include tablet-big {
                width: 16px;
                height: 16px;
                margin: 0 4px; } }

        &__description {
            margin: 0 0 36px 0;

            @include tablet-big {
                max-width: 280px;
                margin: 0 0 28px 0; }

            p {
                @include main-text; } } } }
