.partnership {
    &-top {
        display: flex;
        justify-content: space-between;
        margin: 0 0 100px 0;

        @include tablet-big {
            flex-direction: column-reverse;
            margin: 0 0 48px 0; }

        &__l {
            max-width: 560px; }

        &__headline {
            @include big-head;

            display: block;
            margin: 0 0 40px 0;

            @include tablet-big {
                @include mobile-head;

                margin: 0 0 24px 0; } }

        &__description {
            @include main-text;

            display: block;
            margin: 0 0 32px 0; }

        &__r {
            @include tablet-big {
                margin: 0 0 12px 0; } }

        &__visual {
            width: 360px;

            @include tablet-big {
                width: 215px; } } }

    &-who {
        margin: 0 0 100px 0;

        @include tablet-big {
            margin: 0 0 48px 0; }

        &__headline {
            @include head;

            display: block;
            margin: 0 0 40px 0;

            @include tablet-big {
                margin: 0 0 24px 0; } }

        &__items {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -20px;

            @include tablet-big {
                flex-direction: column;
                margin: 0; } }

        &__item {
            width: 33.33333%;
            padding: 0 20px;
            box-sizing: border-box;

            @include tablet-big {
                width: 100%;
                padding: 0;
                margin: 0 0 28px 0; }

            &:last-of-type {
                @include tablet-big {
                    margin: 0; } } }

        &__visual {
            position: relative;
            padding: 0 0 56% 0;
            margin: 0 0 40px 0;
            border-radius: 4px;
            overflow: hidden;

            @include tablet-big {
                padding: 0 0 35% 0;
                margin: 0 0 20px 0; }

            img {
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover; } }

        &__title {
            @include small-head;

            display: block;
            margin: 0 0 16px 0;

            @include tablet-big {
                margin: 0 0 8px 0; } }

        &__description {
            @include main-text; } }

    &-offer {
        margin: 0 0 100px 0;

        @include tablet-big {
            margin: 0 0 48px 0; }

        &__items {
            display: flex;
            margin: 0 -20px;

            @include tablet-big {
                margin: 0 -16px 0 -8px; }

            .slick {
                &-track {
                    display: flex!important; } } }

        &__item {
            width: 33.33333%;
            padding: 0 20px;
            box-sizing: border-box;

            @include tablet-big {
                width: 244px;
                height: auto;
                padding: 0 8px; } }

        &__content {
            background: $c-grey;
            padding: 36px;
            height: 100%;
            box-sizing: border-box;

            @include tablet-big {
                padding: 20px 16px; } }

        &__visual {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100px;
            height: 100px;
            border-radius: 50%;
            background: $c-white;
            margin: 0 0 32px 0;

            @include tablet-big {
                width: 64px;
                height: 64px;
                margin: 0 0 20px 0; } }

        &__icon {
            width: 52px;

            @include tablet-big {
                width: 33px; } }

        &__headline {
            @include head;

            display: block;
            margin: 0 0 40px 0;

            @include tablet-big {
                margin: 0 0 24px 0; } }

        &__title {
            @include small-head;

            display: block;
            margin: 0 0 24px 0;

            @include tablet-big {
                margin: 0 0 20px 0; } }

        &__list {
            padding: 0;
            margin: 0;
            list-style-type: none;

            li {
                position: relative;
                padding: 0 0 0 40px;
                margin: 0 0 12px 0;

                @include tablet-big {
                    padding: 0 0 0 24px; }

                &::before {
                    content: ' ';
                    position: absolute;
                    top: 2px;
                    left: 0;
                    width: 24px;
                    height: 24px;
                    background: url('../img/icons/checkmark.svg') center center no-repeat;
                    background-size: contain;

                    @include tablet-big {
                        width: 16px;
                        height: 16px;
                        top: 1px; } } } } }

    &-form {
        .form {
            box-shadow: 0px 0px 12px rgba(48, 48, 48, 0.07);

            &__in {
                display: flex; }

            &__l,
            &__r {
                width: 50%;
                box-sizing: border-box; }

            &__l {
                padding: 60px 110px;

                @include tablet-big {
                    width: 100%;
                    padding: 36px 20px; } }

            &__r {
                height: auto;

                @include tablet-big {
                    display: none; } }

            &__visual {
                display: block;
                width: 100%;
                height: 100%; }

            .inp {
                &-group {
                    &--checkbox {
                        label {
                            @include label; } } } } } } }
