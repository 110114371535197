.details {
    display: flex;
    margin: 0 -30px;

    @include tablet-big {
        margin: 0; }

    &--fw {
        margin: 0; }

    &__l {
        flex: 0 0 200px;
        padding: 0 30px;

        @include tablet-big {
            display: none; } }

    &__links {
        border-top: 1px solid $c-grey;
        border-right: 1px solid $c-grey;
        border-left: 1px solid $c-grey; }

    &__link {
        @include transition;
        @include link;
        @include main-text;

        display: block;
        width: 100%;
        padding: 16px 20px;
        box-sizing: border-box;
        color: $c-black;
        border-bottom: 1px solid $c-grey;

        &:hover {
            background: $c-grey; }

        &.is {
            &-active {
                background: $c-grey; } } }

    &__r {
        flex-grow: 1;
        padding: 0 30px;

        @include tablet-big {
            width: 100%;
            padding: 0; } }

    &-item {
        display: flex;

        + .details {
            &-item {
                margin: 60px 0 0 0;

                @include tablet-big {
                    margin: 36px 0 0 0; }

                &--small {
                    margin: 28px 0 0 0;

                    @include tablet-big {
                        margin: 12px 0 0 0; } } } }

        &--delivery {
            @include tablet-big {
                display: flex;
                flex-direction: column; }

            .details {
                &-item {
                    &__l {
                        flex: 0 0 100px;
                        margin: 0 40px 0 0;

                        @include tablet-big {
                            flex: 0 0 auto;
                            margin: 0; } }

                    &__icon {
                        display: flex;
                        justify-content: flex-end;
                        text-align: right;

                        @include tablet-big {
                            justify-content: flex-start;
                            text-align: left;
                            margin: 0 0 12px 0; }

                        img {
                            max-height: 60px;

                            @include tablet-big {
                                max-height: 48px; } } }

                    &__r {
                        flex-grow: 1; } } } }

        &--small {
            .details {
                &-item {
                    &__title {
                        margin: 0 0 16px 0;

                        @include tablet-big {
                            margin: 0 0 8px 0; }

                        .small {
                            &-head {
                                @include tablet-big {
                                    @include mobile-button; } } } } } }

            + .details {
                &-item {
                    margin: 28px 0 0 0;

                    @include tablet-big {
                        margin: 12px 0 0 0; } } } }

        &__title {
            display: block;
            margin: 0 0 20px 0;
            line-height: 1;

            @include tablet-big {
                margin: 0 0 12px 0; }

            .head {
                @include tablet-big {
                    @include mobile-small-head; } } }

        &__desc {
            p {
                @include main-text;

                a {
                    @include transition;
                    @include link;
                    @include button;

                    &:hover {
                        color: $c-red; } }

                + p {
                    margin: 16px 0 0 0; }

                b {
                    @include sans-semibold; } }

            ol {
                margin: 0;
                padding: 0;
                counter-reset: list;

                li {
                    position: relative;
                    padding: 0 0 0 64px;
                    list-style-type: none;
                    margin: 0 0 28px 0;

                    @include tablet-big {
                        padding: 44px 0 0 0; }

                    &:last-of-type {
                        margin: 0; }

                    &::before {
                        position: absolute;
                        top: 0;
                        left: 0;
                        display: block;
                        width: 44px;
                        height: 44px;
                        line-height: 42px;
                        text-align: center;
                        border-radius: 50%;
                        border: 1px solid $c-black;
                        counter-increment: list;
                        content: counter(list);
                        box-sizing: border-box;

                        @include tablet-big {
                            width: 36px;
                            height: 36px;
                            line-height: 34px; } }

                    &.is-centered {
                        padding-top: 8px;

                        @include tablet-big {
                            padding: 44px 0 0 0; } } } } }

        &__img {
            margin: 28px 0;

            @include tablet-big {
                margin: 12px 0; }

            &--card {
                max-width: 323px;

                @include tablet-big {
                    width: 100%;
                    max-width: 400px; } } } } }
