.select {
    position: relative;

    select {
        display: none;
        display: block;
        width: 100%;
        height: 100%;
        opacity: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: 0;
        padding: 0; } }

.jq {
    &-selectbox {
        display: block;
        width: 100%;
        height: 44px;
        cursor: pointer;

        @include tablet-big {
            height: 34px; }

        &.opened {
            .jq {
                &-selectbox {
                    &__trigger {
                        &::after {
                            transform: translate3d(0,-50%,0) rotate(180deg); } } } } }

        &.dropup {
            .jq {
                &-selectbox {
                    &__dropdown {
                        border-radius: 4px 4px 0 0; } } } }

        &.dropdown {
            .jq {
                &-selectbox {
                    &__dropdown {
                        border-radius: 0 0 4px 4px; } } } }

        &__select {
            background: $c-grey;
            border-radius: 4px;
            display: block;
            height: 100%;

            &-text {
                @include main-text;

                height: 100%;
                line-height: 44px;
                padding: 0 16px;
                color: $c-black;

                @include tablet-big {
                    line-height: 34px; } } }

        &__trigger {
            width: 44px;
            height: 44px;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;

            @include tablet-big {
                width: 34px;
                height: 34px; }

            &:after {
                @include transition;

                content: ' ';
                position: absolute;
                top: 50%;
                transform: translate3d(0,-50%,0) rotate(0deg);
                right: 0;
                left: 0;
                width: 8px;
                height: 8px;
                margin: auto;
                background: url('../img/icons/arrow-dropdown-black.svg') center center no-repeat;
                background-size: contain; } }

        &__dropdown {
            position: absolute;
            right: 0;
            left: 0;
            background: $c-grey;

            ul {
                li {
                    @include transition;
                    @include main-text;

                    line-height: 44px;
                    padding: 0 16px;
                    color: $c-black;
                    cursor: pointer;

                    &:hover {
                        background: $c-dark-grey;
                        color: $c-white; } } } } } }
