body {
    @include main-text;

    color: $c-black; }

.headline {
    @include head;

    color: $c-black;
    display: block;
    margin: 0 0 40px 0;

    @include tablet-big {
        margin: 0 0 24px 0; } }

.txt {
    &-red {
        color: $c-red!important; } }

.head {
    @include head; }

.small {
    &-head {
        @include small-head; } }
