.product {
    &-advantages {
        margin: 0 0 100px 0;

        @include tablet-big {
            margin: 0 0 48px 0; }

        &__items {
            display: flex;
            margin: 0 -20px;

            @include tablet-big {
                flex-direction: column; } }

        &__item {
            width: 20%;
            padding: 0 20px;
            box-sizing: border-box;

            @include tablet-big {
                display: flex;
                align-items: flex-start;
                width: 100%;
                margin: 0 0 32px 0; }

            &:last-of-type {
                @include tablet-big {
                    margin: 0; } } }

        &__icon {
            width: 48px;
            margin: 0 0 16px 0;

            @include tablet-big {
                width: 40px;
                margin: 0 20px 0 0; } }

        &__title {
            @include small-head;

            display: block;
            margin: 0 0 12px 0;

            @include tablet-big {
                margin: 0 0 8px 0; }

            br {
                @include tablet-big {
                    display: none; } } }

        &__desc {
            p {
                @include main-text;

                br {
                    @include tablet-big {
                        display: none; } } } } } }
