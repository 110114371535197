.data {
    &-tab {
        &__btns {
            margin: 0 0 20px 0;

            @include tablet-big {
                margin: 0 0 24px 0; } }

        &__item {
            display: none;

            p {
                @include main-text;

                color: $c-gray-4;

                + .bucket-step__btn {
                    margin: 20px 0 0 0;

                    @include tablet-big {
                        margin: 24px 0 0 0; } } }

            &.is {
                &-active {
                    display: block; } } }

        &__content {
            .bucket {
                &-step {
                    &__fields {
                        display: block; } } } } } }
