.inp {
    &-group {
        &--text {
            margin: 0 0 20px 0;

            @include tablet-big {
                margin: 0 0 12px 0; }

            &.inp {
                &-error {
                    label {
                        color: $c-red; }

                    input {
                        border-color: $c-red; } } }

            label {
                @include label;

                display: block;
                margin: 0 0 8px 0;
                color: $c-gray-4; }

            input {
                @include main-text;

                display: block;
                width: 100%;
                height: 52px;
                line-height: 50px;
                border: none;
                box-shadow: none;
                outline: none;
                padding: 0 16px;
                box-sizing: border-box;
                margin: 0;
                border-radius: 4px;
                border: 1px solid $c-gray-4;
                color: $c-black;

                @include tablet-big {
                    height: 44px;
                    line-height: 42px;
                    padding: 0 12px; } }

            .select {
                &2 {
                    .select2-container--default .select2-selection--single {
                        border: 1px solid $c-gray-4;
                        padding: 0 16px;

                        @include tablet-big {
                            padding: 0 12px; }

                        &::before {
                            display: none; } }
                    &-container {
                        .select2-search__field {
                            background: red!important; } } } } }

        &--radio {
            input {
                position: absolute;
                visibility: hidden;
                opacity: 0;
                display: none;

                &:checked {
                    + label {
                        &::before {
                            background: transparent;
                            border: 2px solid $c-dark-grey; }

                        &::after {
                            opacity: 1; } } } }

            label {
                @include transition;
                @include main-text;

                display: block;
                cursor: pointer;
                user-select: none;
                padding: 0 0 0 31px;
                position: relative;

                &::before {
                    @include transition;

                    content: ' ';
                    position: absolute;
                    top: 50%;
                    transform: translate(0, -50%);
                    left: 0;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    border: 2px solid transparent;
                    box-sizing: border-box;
                    background: $c-gray; }

                &::after {
                    @include transition;

                    opacity: 0;
                    content: ' ';
                    position: absolute;
                    top: 50%;
                    transform: translate(0, -50%);
                    left: 6px;
                    width: 8px;
                    height: 8px;
                    background: $c-dark-grey;
                    border-radius: 50%; } } }

        &--textarea {
            margin: 0 0 20px 0;

            label {
                @include label;

                display: block;
                margin: 0 0 8px 0;
                color: $c-gray-4; }

            textarea {
                display: block;
                width: 100%;
                height: 100px;
                line-height: 130%;
                border: none;
                box-shadow: none;
                outline: none;
                padding: 12px 16px 0 16px;
                box-sizing: border-box;
                margin: 0;
                border-radius: 4px;
                border: 1px solid $c-gray-4;
                resize: none; }

            &.inp {
                &-error {
                    label {
                        color: $c-red; }

                    textarea {
                        border-color: $c-red; } } } }

        &--checkbox {
            margin: 0 0 20px 0;

            input {
                position: absolute;
                visibility: hidden;

                &:checked {
                    + label {
                        &::before {
                            background: $c-dark-grey; }

                        &::after {
                            opacity: 1; } } } }

            label {
                display: block;
                padding: 3px 0 0 32px;
                position: relative;
                user-select: none;
                cursor: pointer;
                line-height: 20px;
                min-height: 20px;

                &::before {
                    @include transition;

                    content: ' ';
                    width: 20px;
                    height: 20px;
                    background: $c-gray;
                    border-radius: 2px;
                    position: absolute;
                    top: 0;
                    left: 0; }

                &::after {
                    @include transition;

                    content: ' ';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 20px;
                    height: 20px;
                    background: url('../img/icons/checkbox-white.svg') center center no-repeat;
                    background-size: 12px auto;
                    opacity: 0; } }

            &.inp {
                &-error {
                    label {
                        color: $c-red; } } } }

        &--search {
            position: relative;
            border: 1px solid $c-gray-5;
            border-radius: 4px;

            input {
                display: block;
                width: 100%;
                height: 52px;
                line-height: 52px;
                padding: 0 16px 0 46px;
                box-sizing: border-box;
                border: none;
                outline: none;
                box-shadow: none;
                border-radius: 4px;

                @include tablet-big {
                    height: 44px;
                    line-height: 42px; } }

            &::after {
                content: ' ';
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                left: 16px;
                width: 20px;
                height: 20px;
                background: url('../img/icons/search-gray.svg') center center no-repeat;
                background-size: contain; } } }


    &-groups {
        display: flex;
        margin: 0 -10px;
        width: auto;

        .inp {
            &-group {
                flex-grow: 1;
                padding: 0 10px;
                box-sizing: border-box; } } } }

.select {
    &-search {
        .jq {
            &-selectbox {
                &__dropdown {
                    .jq {
                        &-selectbox {
                            &__search {
                                display: block!important; } } } } } } }
    &2 {
        &-dropdown {
            border-color: #E0E0E0; }

        &-results {
            &__option {
                &--disabled {
                    display: none; }

                &:hover {
                    background: $c-dark-grey; } } }

        &-container {
            display: block;
            position: relative;
            width: 100%;
            height: 52px;

            @include tablet-big {
                height: 40px; }

            .selection {
                display: block;
                height: 100%; }

            &--default {
                .select {
                    &2 {
                        &-selection {
                            &--single {
                                height: 100%;
                                position: relative;
                                color: $c-black;
                                border: none;
                                box-shadow: none;
                                outline: none;
                                padding: 0;
                                display: block;
                                width: 100%;
                                height: 100%;
                                line-height: 50px;
                                padding: 0 16px 0 48px;
                                box-sizing: border-box;
                                border-radius: 4px;
                                border: 1px solid #E0E0E0;

                                @include tablet-big {
                                    line-height: 40px;
                                    padding: 0 16px 0 38px; }

                                &::before {
                                    content: ' ';
                                    position: absolute;
                                    top: 50%;
                                    transform: translate(0, -50%);
                                    left: 16px;
                                    width: 22px;
                                    height: 20px;
                                    background: url('../img/icons/search-gray.svg') center center no-repeat;
                                    background-size: contain;

                                    @include tablet-big {
                                        width: 16px;
                                        height: 16px;
                                        left: 12px; } } }

                            &__rendered {
                                height: 100%;
                                line-height: 50px!important;
                                padding: 0!important;

                                @include tablet-big {
                                    line-height: 38px!important; } }

                            &__arrow {
                                display: none;
                                height: 100%!important;
                                line-height: 50px; } } } } } }


        &-selection {
            display: block;
            height: 100%;

            &--single {
                height: 100%; } }

        &-select {
            display: block;
            position: relative;
            width: 100%;
            height: 52px;

            &::before {
                content: ' ';
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                left: 16px;
                width: 22px;
                height: 20px;
                background: url('../img/icons/search-gray.svg') center center no-repeat;
                background-size: contain; }

            &__field {
                @include main-text;

                color: $c-black;
                border: none;
                box-shadow: none;
                outline: none;
                padding: 0;
                display: block;
                width: 100%;
                height: 100%;
                line-height: 50px;
                padding: 0 16px 0 48px;
                box-sizing: border-box;
                border-radius: 4px;
                border: 1px solid #E0E0E0; } }

        &-search {
            &__field {
                border-color: #E0E0E0; } } } }

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
    background-color: $c-dark-grey; }

.select2-container--open .select2-dropdown--below {
    overflow: hidden; }

.select2-results__option,
.select2-search--dropdown .select2-search__field {
    padding: 0 16px 0 48px;
    height: 52px;
    line-height: 52px;

    @include tablet-big {
        height: 40px;
        line-height: 40px;
        padding: 0 16px 0 38px; } }

.select2-search--dropdown {
    padding: 0;
    border: none;
    border-color: $c-gray-5; }

.select2-container--default .select2-search--dropdown .select2-search__field {
    border: none;
    border-bottom: 1px solid #E0E0E0; }

.form {
    &-title {
        @include head;

        display: block;
        margin: 0 0 32px 0;

        @include tablet-big {
            margin: 0 0 24px 0; } } }
