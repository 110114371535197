.content {
    margin: 0 0 100px 0;

    @include tablet-big {
        margin: 0 0 48px 0; }

    &__head {
        margin: 0 0 40px 0;

        @include tablet-big {
            margin: 0 0 12px 0; } }

    &__headline {
        .headline {
            margin: 0 0 28px 0;

            @include tablet-big {
                margin: 0 0 12px 0; } } } }
