.search {
	@include transition;

	position: fixed;
	top: 80px;
	right: 0;
	left: 0;
	background: $c-white;
	z-index: 999;
	transform: translate3d(0, 10px, 0);
	opacity: 0;
	pointer-events: none;

	@include tablet-big {
		top: 50px; }

	&.is {
		&-active {
			opacity: 1;
			pointer-events: all;
			transform: translate3d(0, 0px, 0); } }

	&__field {
		height: 100px;
		box-shadow: 0px 0px 12px rgba(48, 48, 48, 0.07);

		@include tablet-big {
			height: 52px; }

		.container {
			height: 100%; } }

	&__input {
		display: flex;
		align-items: center;
		height: 100%;

		input {
			outline: none;
			box-shadow: none;
			border: none;
			flex-grow: 1;
			padding: 0;
			margin: 0;
			display: block;
			height: 100%;

			@include placeholder-all {
				@include main-text;

				line-height: 60px;
				color: $c-gray-4; } } }

	&__controls {
		display: flex;
		align-items: center;
		flex: 0 0 270px;
		justify-content: flex-end;

		@include tablet-big {
			flex: 0 0 52px; }

		.btn {
			&-close {
				width: 60px;
				height: 60px;
				background-size: 16px;

				@include tablet-big {
					width: 52px;
					height: 52px;
					margin-right: -16px; } } } }

	&__list {
		background: $c-white; }

	&-item {
		@include link;

		display: block;
		padding: 20px 0;
		border-top: 1px solid $c-gray;
		box-sizing: border-box;

		@include tablet-big {
			padding: 14px 0; }

		.container {
			display: flex;
			align-items: center; }

		&__img {
			width: 80px;
			height: 80px;
			margin: 0 40px 0 0;

			@include tablet-big {
				flex: 0 0 40px;
				width: 40px;
				height: 40px;
				margin: 0 8px 0 0; } }

		&__description {
			@include main-text;

			color: $c-black;

			@include tablet-big {
				flex-grow: 1; }

			b {
				font-weight: $semibold; } } }

	&__btn {
		@include transition;
		@include link;
		@include button;

		display: block;
		height: 100px;
		line-height: 100px;
		background: $c-grey;
		text-align: center;
		color: $c-black;

		@include tablet-big {
			height: 56px;
			line-height: 56px; }

		&:hover {
			color: $c-white;
			background: $c-black; } } }
