@import "vars";
@mixin br($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	-ms-border-radius: $radius;
	border-radius: $radius; }

@mixin clearfix {
	&:after {
		content: ' ';

		visibility: hidden;
		display: block;
		height: 0;

		clear: both; } }

@mixin placeholder-all {
    &.placeholder {
    	@content; }
    &:-moz-placeholder {
    	@content; }
    &::-webkit-input-placeholder {
    	@content; } }

@mixin mobile-small {
	@media(max-width: $mobile-small) {
		@content; } }

@mixin mobile {
	@media(max-width: $mobile ) {
		@content; } }

@mixin tablet {
	@media(max-width: $tablet) {
		@content; } }

@mixin tablet-big {
	@media(max-width: $tablet-big) {
		@content; } }

@mixin desktop-small {
	@media(max-width: $desktop-small) {
		@content; } }

@mixin desktop {
	@media(max-width: $desktop) {
		@content; } }

@mixin desktop-big {
	@media(max-width: $desktop-big) {
		@content; } }

@mixin desktop-only {
	@media(min-width: 1025px) {
		@content; } }

@mixin link {
    text-decoration: none;
    color: inherit;

    &:hover {
        color: inherit; } }

@mixin transition {
    transition: all 0.4s; }

// Typography
@mixin sans-light {
    font-family: $sans;
    font-weight: $light; }

@mixin sans-regular {
    font-family: $sans;
    font-weight: $regular; }

@mixin sans-medium {
    font-family: $sans;
    font-weight: $medium; }

@mixin sans-semibold {
    font-family: $sans;
    font-weight: $semibold; }

@mixin big-head {
    @include sans-semibold;

    font-size: 40px;
    line-height: 48px; }

@mixin head {
    @include sans-semibold;

    font-size: 28px;
    line-height: 36px;

    @include tablet-big {
        @include mobile-head; } }

@mixin mobile-head {
    @include sans-semibold;

    font-size: 18px;
    line-height: 24px; }

@mixin main-text {
    @include sans-light;

    font-size: 16px;
    line-height: 28px;

    @include tablet-big {
        @include mobile-main-text; } }

@mixin mobile-main-text {
    @include sans-light;

    font-size: 12px;
    line-height: 18px; }

@mixin button {
    @include sans-medium;

    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.16px;

    @include tablet-big {
        @include mobile-button; } }

@mixin mobile-button {
    @include sans-medium;

    font-size: 12px;
    line-height: 18px; }

@mixin small-head {
    @include sans-semibold;

    font-size: 18px;
    line-height: 24px;

    @include tablet-big {
        @include mobile-small-head; } }

@mixin mobile-small-head {
    @include sans-medium;

    font-size: 14px;
    line-height: 18px; }

@mixin label {
    @include sans-light;

    font-size: 14px;
    line-height: 16px;

    @include tablet-big {
        @include mobile-label; } }

@mixin mobile-label {
    @include sans-medium;

    font-size: 11px;
    line-height: 14px; }
