.container {
    max-width: 1190px;
    margin: 0 auto;
    padding: 0 15px;
    box-sizing: border-box;

    @include tablet-big {
        padding: 0 16px; } }

.hidden {
    &-mobile {
        @include tablet-big {
            display: none!important; } } }

.visible {
    &-mobile {
        display: none!important;

        @include tablet-big {
            display: flex!important; } } }
