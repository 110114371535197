html,
body {
    margin: 0 auto;
    padding: 0;
    text-decoration: none;

    @include tablet-big {
        overflow-x: hidden; } }

body {
    font-family: $sans;
    padding: 140px 0 0 0;
    // overflow-x: hidden

    @include tablet-big {
        padding: 76px 0 0 0; } }

// Default
.list-unstyled {
    margin: 0;
    padding: 0;

    list-style: none; }

.tac {
    text-align: center; }

object {
    pointer-events: none; }

.cf {
    @include clearfix; }

// Buttons
input, button, textarea {
    outline: none;
    border-radius: 0;
    -webkit-appearance: none; }

h1,
h2,
h3,
h4,
h5,
p {
    margin: 0;
    font-weight: normal; }

ul {
    margin: 0;
    padding: 0;

    li {
        list-style-type: none; } }

.is {
    &-ovh {
        overflow: hidden; } }

