.range {
    padding: 20px 16px;

    @include tablet-big {
        padding: 0; }

    &__inputs {
        display: flex;
        align-items: center;
        margin: 0 0 24px 0;

        @include tablet-big {
            margin: 0 0 16px 0; } }

    &__input {
        @include main-text;

        width: 98px;
        height: 44px;
        line-height: 42px;
        border: 1px solid $c-grey;
        text-align: center;
        padding: 0;
        margin: 0;
        box-sizing: border-box;

        @include tablet-big {
            height: 34px;
            line-height: 32px; }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0; }

        &[type=number] {
            -moz-appearance: textfield; } }

    &__divider {
        @include main-text;

        margin: 0 12px;

        @include tablet-big {
            margin: 0 10px; } } }

.irs {
    &--big {
        height: 64px;

        @include tablet-big {
            height: 58px; }

        .irs {
            height: 100%;

            &-line {
                top: 5px;
                background: transparent;
                border-radius: 0;
                border-color: transparent;

                &:after {
                    content: ' ';
                    position: absolute;
                    top: 50%;
                    transform: translate(0, -50%);
                    right: 0;
                    left: 0;
                    height: 2px;
                    background: $c-grey; } }

            &-bar {
                background: transparent;
                border-radius: 0;
                border-color: transparent;
                top: 5px;

                &:after {
                    content: ' ';
                    position: absolute;
                    top: 50%;
                    transform: translate(0, -50%);
                    right: 0;
                    left: 0;
                    height: 2px;
                    background: $c-dark-grey; } }

            &-handle {
                // top: 28px
                top: 0;
                width: 24px;
                height: 24px;
                background: $c-white;
                border: none;
                box-shadow: 0px 0px 12px rgba(48, 48, 48, 0.07);
                border: 1px solid $c-dark-grey;
                cursor: pointer;

                &:hover {
                    background: $c-white; }

                &::after {
                    content: ' ';
                    position: absolute;
                    top: 50%;
                    transform: translate(0, -50%);
                    right: 0;
                    left: 0;
                    width: 8px;
                    height: 8px;
                    background: $c-dark-grey;
                    border-radius: 50%;
                    margin: auto; } }

            &-min,
            &-max {
                @include label;

                position: absolute;
                top: initial;
                color: $c-gray-4;
                padding: 0;
                visibility: visible!important;

                @include tablet-big {
                    @include mobile-label;

                    text-decoration: none; } }

            &-min {
                bottom: 0;
                left: 0; }

            &-max {
                right: 0;
                bottom: 0; } } } }
