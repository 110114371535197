.header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    background: $c-white;
    box-shadow: 0px 0px 12px rgba(48, 48, 48, 0.07);
    z-index: 1002;

    @include tablet-big {
        position: fixed; }

    .container {
        @include tablet-big {
            height: 100%; } }

    @include tablet-big {
        height: 50px; }

    &__in {
        display: flex;
        justify-content: space-between;

        @include tablet-big {
            height: 100%; } }

    &__l {
        display: flex;
        align-items: center; }

    &-logo {
        display: flex;
        align-items: center;
        width: 89px;
        height: 80px;
        margin: 0 40px 0 0;

        @include tablet-big {
            width: auto;
            height: auto;
            margin: 0; }

        &__img {
            display: block;
            width: 100%;

            @include tablet-big {
                width: 60px;
                height: auto; } } }

    &-nav {
        display: flex;
        align-items: center;
        margin: 0 -12px;

        @include tablet-big {
            display: none; }

        li {
            position: relative;
            margin: 0 12px;

            &:hover {
                & > ul {
                    transform: translate3d(0, 0px, 0);
                    opacity: 1;
                    pointer-events: all; } }

            a {
                @include transition;
                @include link;

                display: inline-block;
                height: 80px;
                line-height: 80px;

                &:hover {
                    color: $c-red; } } }

        &__submenu {
            @include transition;

            position: absolute;
            top: 100%;
            left: -12px;
            background: $c-white;
            opacity: 0;
            pointer-events: none;
            transform: translate3d(0, 10px, 0);

            li {
                margin: 0;

                a {
                    @include main-text;

                    display: block;
                    height: auto;
                    padding: 0 12px;
                    line-height: 44px;
                    white-space: nowrap; } } }

        &__catalog {
            @include transition;

            opacity: 0;
            pointer-events: none;
            position: fixed;
            top: 80px;
            right: 0;
            left: 0;
            transform: translate3d(0, 10px, 0);
            z-index: 1002;
            background: $c-white;
            box-shadow: 0px 0px 12px rgba(48, 48, 48, 0.07);
            padding: 48px 0 24px 0;

            &.is {
                &-active {
                    transform: translate3d(0, 0px, 0);
                    opacity: 1;
                    pointer-events: all; } }

            &::before {
                content: ' ';
                position: absolute;
                top: -12px;
                right: 0;
                left: 0;
                height: 12px;
                background: $c-white;
                pointer-events: none; }

            &::after {
                content: ' ';
                position: absolute;
                top: 48px;
                bottom: 48px;
                left: 50%;
                transform: translate(-50%, 0);
                width: 1px;
                background: $c-grey;
                pointer-events: none; } } }

    &__r {
        display: flex;
        align-items: center;

        @include tablet-big {
            padding: 0 40px 0 0; } }

    &__controls {
        display: flex;
        height: 100%;

        .header {
            &-control {
                &:first-child {
                    margin: 0; } } } }

    &-control {
        margin: 0 0 0 20px;

        @include tablet-big {
            margin: 0 0 0 12px; }

        &--search {
            margin: 0 32px;

            @include tablet-big {
                margin: 0 0 0 12px; } } }

    &-phone {
        @include link;
        @include main-text;

        display: inline-block;
        height: 28px;
        line-height: 28px;
        position: relative;
        padding: 0 0 0 36px;

        @include tablet-big {
            font-size: 0;
            padding: 0;
            width: 20px;
            height: 100%; }

        &::after {
            content: ' ';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(0, -50%);
            width: 28px;
            height: 28px;
            background: url('../img/icons/phone.svg') center center no-repeat;
            background-size: contain;

            @include tablet-big {
                width: 20px;
                height: 20px;
                background: url('../img/icons/phone-mobile.svg') center center no-repeat;
                background-size: contain; } } }

    &-control {
        height: 100%; } }

.menu {
    &-catalog {
        display: flex;
        margin: 0 -52px;

        @include tablet-big {
            display: none; }

        &__l,
        &__r {
            width: 50%;
            padding: 0 52px;
            box-sizing: border-box; }

        &__title {
            @include small-head;

            display: block;
            margin: 0 0 40px 0; }

        &__items {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -12px; }

        &__item {
            @include link;

            display: flex;
            align-items: center;
            width: 33.33333%;
            max-height: 48px;
            padding: 0 12px;
            margin: 0 0 24px 0;
            box-sizing: border-box;

            &:hover {
                .menu {
                    &-catalog {
                        &__description {
                            color: $c-red; } } } } }

        &__img {
            display: block;
            width: 48px;
            height: 48px;
            object-fit: cover;
            margin: 0 12px 0 0; }

        &__description {
            @include transition;
            @include main-text;

            color: $c-black;
            line-height: 20px; } } }
