.history {
    margin: 0 0 100px 0;

    @include tablet-big {
        margin: 0 0 48px 0;
        overflow-x: hidden; }

    &__items {
        &:hover {
            .slick {
                &-arrow {
                    opacity: 1;
                    pointer-events: all; } } }

        @include tablet-big {
            margin: 0 -16px;
            position: relative;

            &::before {
                content: ' ';
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                background: $c-white;
                width: 16px;
                z-index: 2; } }

        .slick {
            &-track {
                margin-left: 0; }

            &-list {
                @include tablet-big {
                    padding: 0 0 0 16px; } }

            &-arrow {
                @include transition;

                padding: 0;
                position: absolute;
                top: 0;
                bottom: 0;
                cursor: pointer;
                width: 36px;
                height: 100%;
                background: transparent;
                text-indent: -99999px;
                border: none;
                outline: none;
                box-shadow: none;
                z-index: 1001;
                opacity: 0;
                pointer-events: none;

                &:hover {
                    &::after {
                        border-color: $c-dark-grey; } } }

            &-prev {
                position: absolute;
                left: 1px;

                &::after {
                    content: ' ';
                    position: absolute;
                    top: 50%;
                    transform: translate(0, -50%);
                    left: 0;

                    width: 100%;
                    height: 36px;
                    border: 1px solid $c-gray-4;
                    border-radius: 50%;
                    background: $c-white url('../img/icons/arrow-prev.svg') center center no-repeat;
                    background-size: contain; }

                &:hover {
                    &::after {
                        background: $c-dark-grey url('../img/icons/arrow-prev-white.svg') center center no-repeat;
                        background-size: contain; } } }

            &-next {
                position: absolute;
                right: 1px;

                &::after {
                    content: ' ';
                    position: absolute;
                    top: 50%;
                    transform: translate(0, -50%);
                    right: 0;

                    width: 100%;
                    height: 36px;
                    border: 1px solid $c-gray-4;
                    border-radius: 50%;
                    background: $c-white url('../img/icons/arrow-next.svg') center center no-repeat;
                    background-size: contain; }

                &:hover {
                    &::after {
                        background: $c-dark-grey url('../img/icons/arrow-next-white.svg') center center no-repeat;
                        background-size: contain; } } } } }

    &-item {
        @include link;

        display: block;
        border: 1px solid $c-grey;
        padding: 30px 20px 30px 24px;
        box-sizing: border-box;
        outline: none;

        &:hover,
        &:focus,
        &:hover:focus {
            outline: none;
            -webkit-tap-highlight-color: transparent; }

        @include tablet-big {
            width: 128px;
            margin: 0 0 0 0;
            padding: 20px; }

        &:nth-child(4n) {
            border-right: 1px solid $c-grey; }

        &__in {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include tablet-big {
                flex-direction: column-reverse; } }

        &__l {
            width: 88px; }

        &__name {
            @include sans-semibold;

            display: block;
            margin: 0 0 4px 0;
            font-size: 16px;
            line-height: 18px;

            @include tablet-big {
                @include mobile-small-head; } }

        &__title {
            @include sans-light;

            display: block;
            min-height: 40px;
            margin: 0 0 10px 0;
            font-size: 16px;
            line-height: 20px;

            @include tablet-big {
                @include mobile-main-text;

                margin: 0 0 8px 0; } }

        &__price {
            @include main-text; }

        &__r {
            width: 120px;
            height: 120px;

            @include tablet-big {
                width: 88px;
                height: 88px; } }

        &__visual {
            width: 100%;
            height: 100%; }

        &__img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;

            @include tablet-big {
                margin-left: auto;
                margin-right: auto; } } } }
