// Modals
.modal-open {
    overflow: hidden;

    .modal {
        overflow-x: hidden;
        overflow-y: auto;
    }
}

.fade {
    opacity: 0;

    transition: opacity 0.15s linear;

    &.in {
        opacity: 1;
    }
}

.modal {
    z-index: 100000000;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    overflow: hidden;
    display: none;

    outline: 0;

    -webkit-overflow-scrolling: touch;
    

    &-certificate {
        &__img {
            display: block;
            width: 100%;
        }
    }

    &.fade {
        .modal-dialog {
            transform: translate(0, -25%);
            transition: transform 0.3s ease-out;
        }
    }

    &.in {
        .modal-dialog {
            transform: translate(0, 0);
        }
    }

    &-btns {
        padding: 0 0 400px 0;
    }

    &-btn {
        margin: 0 0 24px 0;
    }

    &-form {
        .modal {
            &-dialog {
                max-width: 520px;
                background: $c-white;
                padding: 80px;
                box-sizing: border-box;

                @include tablet-big {
                    max-width: 90%;
                    padding: 36px 20px;
                    border-radius: 4px;
                }
            }

            &-content {
                position: static;

                .btn {
                    &-close {
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 82px;
                        height: 82px;
                        background-size: 20px;

                        @include tablet-big {
                            width: 64px;
                            height: 64px;
                        }
                    }
                }
            }
        }

        .inp {
            &-group {
                &--checkbox {
                    label {
                        @include label;

                        line-height: 20px;
                    }
                }
            }
        }

        &-image {
            .form {
                &__in {
                    display: flex;
                }

                &__l,
                &__r {
                    width: 50%;
                    box-sizing: border-box;
                }

                &__l {
                    position: relative;

                    @include tablet-big {
                        display: none;
                    }
                }

                &__r {
                    padding: 80px;

                    @include tablet-big {
                        width: 100%;
                        padding: 36px 20px;
                    }
                }
                
                &-title {
                    margin: 0 0 16px 0;
                }

                &-subtitle {
                    @include main-text;

                    display: block;
                    margin: 0 0 32px 0;

                    @include tablet-big {
                        margin: 0 0 24px 0;
                    }
                }

                &__visual {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .inp {
                    &-group {
                        &--checkbox {
                            label {
                                @include label;
        
                                line-height: 20px;
                            }
                        }
                    }
                }
            }

            .modal {
                &-dialog {
                    width: 1040px;
                    background: $c-white;
                }
    
                &-content {
                    position: static;
    
                    .btn {
                        &-close {
                            position: absolute;
                            top: 0;
                            right: 0;
                            width: 84px;
                            height: 84px;
                            background-size: 20px;

                            @include tablet-big {
                                width: 64px;
                                height: 64px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.modal-dialog {
    position: relative;
    width: 780px;
    margin: 80px auto 40px auto;

    @include tablet-big {
        max-width: 90%;
        margin-top: 24px;
        border-radius: 4px;
    }
}

.modal-content {
    position: relative;

    box-sizing: border-box;

    -webkit-background-clip: padding-box;
    background-clip: padding-box;

    outline: 0;
}

.modal-backdrop {
    z-index: 1040;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &.fade {
        opacity: 0;
        background: $c-black;
    }

    &.in {
        opacity: 0.9;
        background: $c-black;
    }
}
