.footer {
    background: $c-grey;
    padding: 60px 0 80px 0;

    @include tablet-big {
        padding: 24px 0 40px 0; }

    &__in {
        display: flex;
        justify-content: space-between;

        @include tablet-big {
            flex-direction: column; } }

    &__l {
        width: 160px;

        @include tablet-big {
            width: 100%;
            margin: 0 0 32px 0;
            order: 1; } }

    &__c {
        width: 375px;

        @include tablet-big {
            width: 100%;
            order: 3; } }

    &__r {
        width: 348px;

        @include tablet-big {
            width: 100%;
            order: 2;
            margin: 0 0 30px 0; }

        .btn {
            @include tablet-big {
                display: block;
                width: 100%; } } }

    &-links {
        margin: 0 0 40px 0;

        @include tablet-big {
            margin: 0 0 24px 0; }

        &--triple {
            ul {
                column-count: 3; } }

        ul {
            li {
                margin: 0 0 12px 0;

                a {
                    display: block; } } } }

    a {
        &.footer {
            &-link {
                @include transition;

                &:hover {
                    @include desktop-only {
                        color: $c-red; } } } } }

    &-link {
        @include link;
        @include main-text;

        &--icon {
            position: relative;
            padding: 0 0 0 32px;

            @include tablet-big {
                padding: 0 0 0 28px; }

            &::after {
                content: ' ';
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                left: 0;
                width: 24px;
                height: 24px; } }

        &-gray {
            color: $c-gray-4;

            &:hover {
                color: $c-gray-4; } }

        &-phone {
            &::after {
                background: url('../img/icons/phone-red.svg') center center no-repeat;
                background-size: contain; } }

        &-mail {
            &::after {
                background: url('../img/icons/mail-red.svg') center center no-repeat;
                background-size: contain; } }

        &-time {
            &::after {
                background: url('../img/icons/time-red.svg') center center no-repeat;
                background-size: contain; } }

        &-online {
            &::after {
                background: url('../img/icons/online-red.svg') center center no-repeat;
                background-size: contain; } } }

    &-socials {
        display: flex;
        margin: 0 -10px;

        @include tablet-big {
            margin: 0 -6px; }

        &__item {
            @include transition;

            display: block;
            width: 60px;
            height: 60px;
            background: $c-white;
            border-radius: 50%;
            margin: 0 10px;

            @include tablet-big {
                width: 52px;
                height: 52px;
                margin: 0 6px; }

            &--fb {
                background: $c-white url('../img/icons/facebook.svg') center center no-repeat;
                background-size: contain;

                &:hover {
                    background: $c-dark-grey url('../img/icons/facebook-white.svg') center center no-repeat;
                    background-size: contain; } }


            &--instagram {
                background: $c-white url('../img/icons/instagram.svg') center center no-repeat;
                background-size: contain;

                &:hover {
                    background: $c-dark-grey url('../img/icons/instagram-white.svg') center center no-repeat;
                    background-size: contain; } } } }

    &-info {
        padding: 6px 0;

        @include tablet-big {
            padding: 0; }

        p {
            @include sans-regular;

            font-size: 14px;
            line-height: 24px;
            color: $c-gray-4;

            @include tablet-big {
                @include mobile-main-text;

                margin: 0 0 30px 0; }

            &:last-of-type {
                @include tablet-big {
                    margin: 0; } } } } }
