.work {
    &-slider {
        position: relative;
        margin: 0 0 60px 0;
        border-radius: 4px;
        overflow: hidden;

        @include tablet-big {
            height: 214px;
            margin: 0 0 48px 0; }

        .slick {
            &-slider,
            &-list,
            &-track {
                @include tablet-big {
                    height: 100%; } } }

        &::after {
            content: ' ';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: linear-gradient(0deg, #000 0%, rgba(72, 72, 72, 0) 30%);
            pointer-events: none; }

        &__img {
            display: block;
            width: 100%;

            @include tablet-big {
                height: 100%;
                object-fit: cover; } }

        // .slick
        //     &-arrow
        //         background: transparent
        //         font-size: 0
        //         text-indent: -99999px
        //         width: 36px
        //         height: 36px
        //         border-radius: 50%
        //         border: 1px solid $c-white

        &__wrapper {
            position: relative;

            .controls {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;

                &__arrows {
                    width: 84px;
                    height: 36px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    position: relative;

                    @include tablet-big {
                        display: none; }

                    .slick {
                        &-arrow {
                            padding: 0;
                            cursor: pointer;
                            width: 36px;
                            height: 100%;
                            background: transparent;
                            text-indent: -99999px;
                            border: none;
                            outline: none;
                            box-shadow: none; }

                        &-prev {
                            position: absolute;
                            left: 0;

                            &::after {
                                content: ' ';
                                position: absolute;
                                top: 50%;
                                transform: translate(0, -50%);
                                left: 0;

                                width: 100%;
                                height: 36px;
                                border: 1px solid $c-white;
                                border-radius: 50%;
                                background: url('../img/icons/arrow-prev-white.svg') center center no-repeat;
                                background-size: contain;
                                box-sizing: border-box; }

                            &:hover {
                                &:after {
                                    background: $c-white url('../img/icons/arrow-prev.svg') center center no-repeat;
                                    background-size: contain; } } }

                        &-next {
                            position: absolute;
                            right: 0;

                            &::after {
                                content: ' ';
                                position: absolute;
                                top: 50%;
                                transform: translate(0, -50%);
                                right: 0;

                                width: 100%;
                                height: 36px;
                                border: 1px solid $c-white;
                                border-radius: 50%;
                                background: url('../img/icons/arrow-next-white.svg') center center no-repeat;
                                background-size: contain;
                                box-sizing: border-box; }

                            &:hover {
                                &:after {
                                    background: $c-white url('../img/icons/arrow-next.svg') center center no-repeat;
                                    background-size: contain; } } } } }

                &__dots {
                    margin: 0 -10px -8px 0;

                    @include tablet-big {
                        margin: 0 -3px 0 0; }

                    .slick {
                        &-dots {
                            display: flex;

                            li {
                                font-size: 0;

                                button {
                                    position: relative;
                                    background: transparent;
                                    padding: 0;
                                    border: none;
                                    outline: none;
                                    box-shadow: none;
                                    text-indent: -99999px;
                                    width: 28px;
                                    height: 28px;
                                    cursor: pointer;

                                    @include tablet-big {
                                        width: 12px;
                                        height: 12px; }

                                    &::before {
                                        @include transition;

                                        content: ' ';
                                        position: absolute;
                                        top: 50%;
                                        transform: translate(0, -50%);
                                        left: 0;
                                        right: 0;
                                        margin: auto;
                                        width: 8px;
                                        height: 8px;
                                        background: $c-white;
                                        border-radius: 50%;
                                        opacity: 0;

                                        @include tablet-big {
                                            width: 6px;
                                            height: 6px; } }

                                    &::after {
                                        @include transition;

                                        content: ' ';
                                        position: absolute;
                                        top: 50%;
                                        transform: translate(0, -50%);
                                        left: 0;
                                        right: 0;
                                        margin: auto;
                                        width: 5px;
                                        height: 5px;
                                        background: transparent;
                                        border-radius: 50%;
                                        border: 1px solid $c-white;
                                        box-sizing: border-box;

                                        @include tablet-big {
                                            width: 3px;
                                            height: 3px; } } }

                                &.slick {
                                    &-active {
                                        button {
                                            &::before {
                                                opacity: 1; } } } } } } } } } }

        &__controls {
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            padding: 0 40px 40px 40px;

            @include tablet-big {
                padding: 0 20px 20px 20px; } } }

    &__content {
        margin: 0 0 60px 0;

        @include tablet-big {
            margin: 0 0 48px 0; }

        .head {
            display: block;
            margin: 0 0 20px 0; }

        p {
            @include main-text;

            & + p {
                margin-top: 16px; } } } }
