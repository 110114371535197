.slider {
    &-collection {
        margin: 0 0 100px 0;

        @include tablet-big {
            margin: 0 0 48px 0; }

        &__wrapper {
            position: relative;
            height: 440px;

            @include tablet-big {
                height: 264px; } }

        &__items {
            border-radius: 4px;
            height: 100%;
            overflow: hidden;

            .slick {
                &-list {
                    height: 100%;
                    border-radius: 4px; }

                &-track {
                    height: 100%; } } }

        &__item {
            position: relative;
            height: 100%;
            // border-radius: 4px
 }            // overflow: hidden

        &__content {
            padding: 40px 52px 40px 40px;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 440px;
            overflow: hidden;
            z-index: 2;
            box-sizing: border-box;

            @include tablet-big {
                width: 100%;
                height: 132px;
                padding: 20px;
                box-sizing: border-box; }

            &::after {
                content: ' ';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: rgba(#3E3B36, 0.55);
                z-index: -1;

                @include tablet-big {
                    height: 100%;
                    bottom: initial; } }

            .slider {
                &-collection {
                    &__img {
                        width: auto;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        filter: blur(3px);
                        z-index: -2;
                        overflow: hidden;
                        will-change: auto; } } } }

        &__name {
            @include big-head;

            display: block;
            margin: 0 0 20px 0;
            color: $c-white;

            @include tablet-big {
                @include mobile-head;

                max-width: 180px;
                margin: 0 0 8px 0; } }

        &__desc {
            @include tablet-big {
                max-width: 180px; }

            p {
                @include main-text;

                color: $c-white; } }

        &__visual {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0; }

        &__img {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            object-fit: cover; }

        &__blur {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 440px;
            height: 100%;
            overflow: hidden;

            @include tablet-big {
                width: 100%;
                height: 132px; }

            .slider {
                &-collection {
                    &__img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        width: auto;
                        height: 100%;
                        filter: blur(4px);

                        @include tablet-big {
                            width: 100%;
                            height: 264px; } } } } }

        &__controls {
            width: 440px;
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            padding: 0 40px 40px 40px;
            box-sizing: border-box;

            @include tablet-big {
                padding: 0;
                width: auto;
                top: 20px;
                right: 20px;
                bottom: initial;
                left: initial; }

            .controls {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;

                &__arrows {
                    width: 84px;
                    height: 36px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    position: relative;

                    @include tablet-big {
                        display: none; }

                    .slick {
                        &-arrow {
                            padding: 0;
                            cursor: pointer;
                            width: 36px;
                            height: 100%;
                            background: transparent;
                            text-indent: -99999px;
                            border: none;
                            outline: none;
                            box-shadow: none; }

                        &-prev {
                            position: absolute;
                            left: 0;

                            &::after {
                                content: ' ';
                                position: absolute;
                                top: 50%;
                                transform: translate(0, -50%);
                                left: 0;

                                width: 100%;
                                height: 36px;
                                border: 1px solid $c-white;
                                border-radius: 50%;
                                background: url('../img/icons/arrow-prev-white.svg') center center no-repeat;
                                background-size: contain;
                                box-sizing: border-box; }

                            &:hover {
                                &:after {
                                    background: $c-white url('../img/icons/arrow-prev.svg') center center no-repeat;
                                    background-size: contain; } } }

                        &-next {
                            position: absolute;
                            right: 0;

                            &::after {
                                content: ' ';
                                position: absolute;
                                top: 50%;
                                transform: translate(0, -50%);
                                right: 0;

                                width: 100%;
                                height: 36px;
                                border: 1px solid $c-white;
                                border-radius: 50%;
                                background: url('../img/icons/arrow-next-white.svg') center center no-repeat;
                                background-size: contain;
                                box-sizing: border-box; }

                            &:hover {
                                &:after {
                                    background: $c-white url('../img/icons/arrow-next.svg') center center no-repeat;
                                    background-size: contain; } } } } }

                &__dots {
                    margin: 0 -10px -8px 0;

                    @include tablet-big {
                        margin: 0 -3px 0 0; }

                    .slick {
                        &-dots {
                            display: flex;

                            li {
                                font-size: 0;

                                button {
                                    position: relative;
                                    background: transparent;
                                    padding: 0;
                                    border: none;
                                    outline: none;
                                    box-shadow: none;
                                    text-indent: -99999px;
                                    width: 28px;
                                    height: 28px;
                                    cursor: pointer;

                                    @include tablet-big {
                                        width: 12px;
                                        height: 12px; }

                                    &::before {
                                        @include transition;

                                        content: ' ';
                                        position: absolute;
                                        top: 50%;
                                        transform: translate(0, -50%);
                                        left: 0;
                                        right: 0;
                                        margin: auto;
                                        width: 8px;
                                        height: 8px;
                                        background: $c-white;
                                        border-radius: 50%;
                                        opacity: 0;

                                        @include tablet-big {
                                            width: 6px;
                                            height: 6px; } }

                                    &::after {
                                        @include transition;

                                        content: ' ';
                                        position: absolute;
                                        top: 50%;
                                        transform: translate(0, -50%);
                                        left: 0;
                                        right: 0;
                                        margin: auto;
                                        width: 5px;
                                        height: 5px;
                                        background: transparent;
                                        border-radius: 50%;
                                        border: 1px solid $c-white;
                                        box-sizing: border-box;

                                        @include tablet-big {
                                            width: 3px;
                                            height: 3px; } } }

                                &.slick {
                                    &-active {
                                        button {
                                            &::before {
                                                opacity: 1; } } } } } } } } } } } }
