.breadcrumb {
    display: flex;
    flex-wrap: wrap;

    li {
        @include main-text;

        color: $c-gray-4;
        padding: 0 34px 0 0;
        position: relative;

        @include tablet-big {
            padding: 0 23px 0 0;
            margin: 0 0 8px 0; }

        &::after {
            @include main-text;

            content: '>';
            position: absolute;
            right: 0;
            text-align: center;
            width: 34px;
            height: 28px;
            color: $c-gray-4;

            @include tablet-big {
                width: 23px;
                height: 18px; } }

        &:last-of-type {
            &::after {
                display: none; } }

        a {
            @include link;
            @include main-text;

            display: inline-block;
            color: $c-gray-4; } } }
