.btn {
    @include link;

    display: inline-block;
    box-shadow: none;
    outline: none;
    padding: 0;
    margin: 0;
    border: none;
    background: transparent;
    cursor: pointer;

    &__counter {
        @include sans-light;

        font-size: 8px;
        display: block;
        min-width: 14px;
        min-height: 14px;
        line-height: 14px;
        background: $c-grey;
        border-radius: 50%;
        position: relative;
        top: -5px; }

    &-search {
        position: relative;
        width: 26px;
        height: 100%;

        @include tablet-big {
            width: 20px; }

        &::after {
            content: ' ';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            right: 0;
            left: 0;
            background: url('../img/icons/search.svg') center center no-repeat;
            background-size: 100% auto; } }

    &-compare {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;

        &__icon {
            display: block;
            width: 24px;
            height: 24px;
            background: url('../img/icons/compare.svg') center center no-repeat;
            background-size: 100% auto;

            @include tablet-big {
                width: 20px;
                height: 20px; }

            + .btn {
                &__counter {
                    margin: 0 0 0 4px; } } }

        &--gray {
            width: 32px;
            height: 32px;

            &::after {
                content: ' ';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: url('../img/icons/compare-gray.svg') center center no-repeat;
                background-size: contain;
                border-radius: 50%; }

            &.is {
                &-active {
                    &::after {
                        background: $c-dark-grey url('../img/icons/compare-white.svg') center center no-repeat;
                        background-size: contain; } } } } }

    &-favorite {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;

        &__icon {
            display: block;
            width: 24px;
            height: 24px;
            background: url('../img/icons/favorite.svg') center center no-repeat;
            background-size: 100% auto;

            @include tablet-big {
                width: 20px;
                height: 20px; }

            + .btn {
                &__counter {
                    margin: 0 0 0 4px; } } }

        &--gray {
            width: 32px;
            height: 32px;

            &::after {
                content: ' ';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: url('../img/icons/favorite-gray.svg') center center no-repeat;
                background-size: contain;
                border-radius: 50%; }

            &.is {
                &-active {
                    &::after {
                        background: $c-dark-grey url('../img/icons/favorite-white.svg') center center no-repeat;
                        background-size: contain; } } } } }


    &-bucket {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;

        &__icon {
            display: block;
            width: 24px;
            height: 24px;
            background: url('../img/icons/bucket.svg') center center no-repeat;
            background-size: 100% auto;

            @include tablet-big {
                width: 20px;
                height: 20px; }

            + .btn {
                &__counter {
                    margin: 0 0 0 4px; } } }

        &--gray {
            width: 32px;
            height: 32px;

            &::after {
                content: ' ';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: url('../img/icons/bucket-gray.svg') center center no-repeat;
                background-size: contain; } }

        &-plus {
            // +transition

            width: 60px;
            height: 60px;
            border-radius: 50%;
            background: $c-grey url('../img/icons/bucket-plus.svg') center center no-repeat;
            background-size: contain;

            @include tablet-big {
                width: 48px;
                height: 48px; }

            &:hover {
                background-color: $c-gray-4; }

            &.is-active {
                background: $c-dark-grey url('../img/icons/bucket-check.svg') center center no-repeat;
                background-size: contain; } } }

    &-phone {
        @include link;

        position: relative;
        height: 100%;
        display: flex;
        align-items: center;

        &__icon {
            display: block;
            width: 24px;
            height: 24px;
            background: url(../img/icons/phone-mobile.svg) center center no-repeat;
            background-size: 100% auto;

            @include tablet-big {
                width: 20px;
                height: 20px; }

            + .btn {
                &__counter {
                    margin: 0 0 0 4px; } } }
        &__icon {
            display: block;
            width: 24px;
            height: 24px;
            background: url('../img/icons/phone-mobile.svg') center center no-repeat;
            background-size: 100% auto;

            @include tablet-big {
                width: 20px;
                height: 20px; }

            + .btn {
                &__counter {
                    margin: 0 0 0 4px; } } } }

    &-bordered {
        @include button;
        @include transition;

        height: 60px;
        line-height: 58px;
        padding: 0 48px;
        box-sizing: border-box;
        border: 1px solid $c-black;
        border-radius: 50px;

        &:hover {
            @include desktop-only {
                border-color: $c-dark-grey;
                background-color: $c-dark-grey;
                color: $c-white; } }

        &:active {
            @include tablet-big {
                border-color: $c-dark-grey;
                background-color: $c-dark-grey;
                color: $c-white; } }

        @include tablet-big {
            height: 52px;
            line-height: 50px;
            padding: 0 40px; }

        &--gray {
            border-color: $c-gray-5; } }

    &-filter {
        display: inline-block;
        height: 34px;
        line-height: 34px;
        padding-right: 20px;
        padding-left: 20px;
        background: $c-grey;
        border-radius: 100px;

        span {
            @include main-text;

            display: inline-block;
            position: relative;
            height: 100%;
            line-height: 34px!important;
            padding: 0 0 0 16px;

            &::after {
                content: ' ';
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                left: 0;
                width: 10px;
                height: 10px;
                background: url('../img/icons/filter.svg') center center no-repeat;
                background-size: contain; } } }

    &-subscribe {
        @include transition;

        padding: 0 40px;
        height: 60px;
        line-height: 60px;
        background: $c-white;
        border-radius: 50px;

        @include tablet-big {
            height: 52px;
            line-height: 52px; }

        span {
            @include transition;
            @include button;

            position: relative;
            padding: 0 0 0 47px;

            @include tablet-big {
                padding: 0 0 0 34px; }

            &::after {
                content: ' ';
                position: absolute;
                left: 0;
                top: 50%;
                transform: translate(0, -50%);
                width: 27px;
                height: 20px;
                background: url('../img/icons/subscribe-red.svg') center center no-repeat;
                background-size: contain;

                @include tablet-big {
                    width: 22px;
                    height: 16px; } } }

        &:hover {
            @include desktop-only {
                background: $c-dark-grey;

                span {
                    color: $c-white;

                    &::after {
                        background: url('../img/icons/subscribe-white.svg') center center no-repeat;
                        background-size: contain; } } } }

        &--dark {
            &-grey {
                border: 1px solid $c-dark-grey;
                line-height: 58px;

                @include tablet-big {
                    line-height: 50px; }

                span {
                    &::after {
                        background: url(../img/icons/subscribe-dark-grey.svg) center center no-repeat;
                        background-size: contain; } } } } }

    &-primary {
        @include button;

        height: 60px;
        line-height: 60px;
        padding: 0 60px;
        border-radius: 50px;
        background: $c-dark-grey;
        color: $c-white;

        &:hover {
            color: $c-white; }

        @include tablet-big {
            font-size: 14px;
            height: 52px;
            line-height: 52px;
            padding: 0 54px; } }

    &-gray,
    &-inactive {
        @include button;

        height: 60px;
        line-height: 60px;
        padding: 0 60px;
        border-radius: 50px;
        background: $c-grey;
        color: $c-gray-4;

        &:hover {
            color: $c-gray-4; }

        @include tablet-big {
            height: 52px;
            line-height: 52px;
            padding: 0 54px; } }

    &-white {
        @include button;

        height: 60px;
        line-height: 60px;
        padding: 0 60px;
        border-radius: 50px;
        background: $c-white;
        color: $c-gray-4;

        &:hover {
            color: $c-gray-4; }

        @include tablet-big {
            height: 52px;
            line-height: 52px;
            padding: 0 54px; } }

    &-back {
        width: 36px;
        height: 36px;
        background: $c-grey url('../img/icons/arrow-prev-menu.svg') center center no-repeat;
        background-size: contain;
        border-radius: 50%; }

    &-prev {
        @include button;

        padding: 0 0 0 40px;
        position: relative;
        color: $c-gray-4;

        &::before {
            content: ' ';
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            left: 0;
            width: 24px;
            height: 12px;
            background: url('../img/icons/arrow-prev-gray-4.svg') center center no-repeat;
            background-size: contain; }

        &:hover {
            color: $c-gray-4; } }

    &-block {
        display: block;
        width: 100%;
        box-sizing: border-box;
        text-align: center; }

    &-burger {
        width: 20px;
        // height: 20px
        height: 16px;
        position: relative;
        z-index: 1002;
        outline: none;
        border: none;
        padding: 0;
        background: transparent;

        &__wrapper {
            position: fixed;
            top: 0;
            right: 0;
            width: 52px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 0;
            z-index: 100000000;
            // background: $c-white

            &.visible-xs {
                @include mobile {
                    display: flex!important;
                    justify-content: flex-end; } } }

        i {
            position: absolute;
            background: $c-black;
            display: block;
            width: 100%;
            height: 1px;
            right: 0;
            left: 0;
            transform: rotate(0deg);
            transition: .25s ease-in-out;

            &:first-child {
                top: 0; }

            &:nth-child(2) {
                // top: 9px
                top: 7px; }

            &:nth-child(3) {
                // top: 19px
                top: 15px; } }

        &.is-open {
            i {
                background: $c-black;

                &:nth-child(1) {
                    // top: 9px
                    top: 7px;
                    transform: rotate(135deg); }

                &:nth-child(2) {
                    opacity: 0;
                    left: -30px; }

                &:nth-child(3) {
                    // top: 9px
                    top: 7px;
                    transform: rotate(-135deg); } } } }

    &-vk {
        @include transition;

        position: relative;
        width: 40px;
        height: 40px;
        border-radius: 50%;

        &:hover {
            background: $c-gray; }

        &::after {
            content: ' ';
            position: absolute;
            width: 100%;
            height: 100%;
            background: url('../img/icons/socials/vk.svg') center center no-repeat;
            background-size: contain; } }

    &-fb {
        @include transition;

        position: relative;
        width: 40px;
        height: 40px;
        border-radius: 50%;

        &:hover {
            background: $c-gray; }

        &::after {
            content: ' ';
            position: absolute;
            width: 100%;
            height: 100%;
            background: url('../img/icons/socials/fb.svg') center center no-repeat;
            background-size: contain; } }

    &-ok {
        @include transition;

        position: relative;
        width: 40px;
        height: 40px;
        border-radius: 50%;

        &:hover {
            background: $c-gray; }

        &::after {
            content: ' ';
            position: absolute;
            width: 100%;
            height: 100%;
            background: url('../img/icons/socials/ok.svg') center center no-repeat;
            background-size: contain; } }

    &-instagram {
        @include transition;

        position: relative;
        width: 40px;
        height: 40px;
        border-radius: 50%;

        &:hover {
            background: $c-gray; }

        &::after {
            content: ' ';
            position: absolute;
            width: 100%;
            height: 100%;
            background: url('../img/icons/socials/instagram-dark-grey.svg') center center no-repeat;
            background-size: contain; } }

    &-question {
        height: 60px;
        line-height: 58px;
        padding: 0 45px;
        border: 1px solid $c-dark-grey;
        border-radius: 50px;

        @include tablet-big {
            height: 52px;
            line-height: 50px; }

        span {
            position: relative;
            padding: 0 0 0 40px;
            color: $c-dark-grey;

            @include tablet-big {
                padding: 0 0 0 36px; }

            &::before {
                content: ' ';
                position: absolute;
                left: 0;
                top: 50%;
                transform: translate(0, -50%);
                width: 24px;
                height: 24px;
                background: url('../img/icons/question.svg') center center no-repeat;
                background-size: contain;

                @include tablet-big {
                    width: 20px;
                    height: 20px; } } }

        &:hover {
            @include desktop-only {
                background: $c-dark-grey;

                span {
                    color: $c-white;

                    &::before {
                        background: url('../img/icons/question-fill.svg') center center no-repeat;
                        background-size: contain; } } } } }

    &-close {
        width: 12px;
        height: 12px;
        background: url('../img/icons/close-gray.svg') center center no-repeat;
        background-size: contain;

        &-black {
            width: 24px;
            height: 24px;
            background: url('../img/icons/close-black.svg') center center no-repeat;
            background-size: contain; }

        &-text {
            @include transition;
            @include main-text;

            position: relative;
            padding: 0 0 0 16px;
            color: $c-gray-4;

            &:hover {
                color: $c-gray-4; }

            &::before {
                content: ' ';
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                left: 0;
                width: 10px;
                height: 10px;
                background: url('../img/icons/close-bold-gray.svg') center center no-repeat;
                background-size: contain; } } }

    &-link {
        @include transition;
        @include sans-light;

        font-size: 16px;
        color: $c-gray-4;
        border-bottom: 1px solid $c-gray-4;
        line-height: 120%;

        @include tablet-big {
            font-size: 12px;
            line-height: 18px; }

        &:hover {
            color: $c-black;
            border-bottom: 1px solid $c-black; }

        &--no {
            &-border {
                border-bottom: none;

                &:hover {
                    border-bottom: none; } } } }

    &-arrow {
        width: 36px;
        height: 36px;
        background: $c-white url('../img/icons/arrow-next-gray-4.svg') center center no-repeat;
        background-size: contain;
        border-radius: 50%; }

    &-fb,
    &-instagram {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        box-sizing: border-box;

        @include tablet-big {
            width: 40px;
            height: 40px; } }

    &-fb {
        &--dark-grey {
            border: 1px solid $c-dark-grey;

            &::after {
                background: url('../img/icons/facebook-dark-grey.svg') center center no-repeat;
                background-size: contain; }

            &:hover {
                background: $c-dark-grey;

                &::after {
                    background: url('../img/icons/facebook-white.svg') center center no-repeat;
                    background-size: contain; } } } }

    &-instagram {
        &--dark-grey {
            border: 1px solid $c-dark-grey;

            &::after {
                background: url('../img/icons/instagram-dark-grey.svg') center center no-repeat;
                background-size: contain; }

            &:hover {
                background: $c-dark-grey;

                &::after {
                    background: url('../img/icons/instagram-white.svg') center center no-repeat;
                    background-size: contain; } } } }

    &-up {
        position: relative;
        width: 52px;
        height: 52px;
        border-radius: 50%;
        border: 1px solid $c-white;
        box-sizing: border-box;

        &::after {
            content: ' ';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: $c-gray url('../img/icons/arrow-up.svg') center center no-repeat;
            background-size: contain;
            border-radius: 50%; } } }

.scroll {
    &-up {
        position: fixed;
        right: 24px;
        bottom: 24px;
        z-index: 101;

        @include tablet-big {
            right: 16px;
            bottom: 16px; } } }

.toggle {
    &-button {
        input {
            position: absolute;
            opacity: 0;
            visibility: hidden; }

        label {
            position: relative;
            display: flex;
            padding: 0 0 0 52px;
            cursor: pointer;

            @include tablet-big {
                padding: 0 0 0 44px; }

            &::before {
                @include transition;

                content: ' ';
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                left: 0;
                width: 40px;
                height: 20px;
                background: $c-grey;
                border-radius: 100px;

                @include tablet-big {
                    width: 32px;
                    height: 16px; } }

            &::after {
                @include transition;

                content: ' ';
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                left: 0;
                width: 20px;
                height: 20px;
                background: $c-dark-grey;
                border-radius: 50%;

                @include tablet-big {
                    width: 16px;
                    height: 16px; } } }

        input {
            &:checked {
                + label {
                    &::before {
                        background: $c-dark-grey; }

                    &::after {
                        background: $c-white;
                        transform: translate(20px, -50%);

                        @include tablet-big {
                            transform: translate(16px, -50%); } } } } } } }

