.portfolio {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;

    &-wrapper {
        .head {
            display: block;
            margin: 0 0 40px 0;

            @include tablet-big {
                margin: 0 0 28px 0; } } }

    &--mini {
        display: flex;

        @include tablet-big {
            margin: 0 -16px 0 -22px;
            padding: 0 0 0 16px; }

        .portfolio {
            &-item {
                width: 33.33333%;
                margin: 0;

                @include tablet-big {
                    width: auto;
                    padding: 0 6px; }

                &__in {
                    @include tablet-big {
                        padding: 0;
                        width: 180px;
                        height: 180px; } }

                &__desc {
                    @include button;

                    padding: 28px;

                    @include tablet-big {
                        padding: 16px; } }

                &__headline {
                    @include button; } } } }

    &-item {
        width: 50%;
        padding: 0 20px;
        box-sizing: border-box;
        margin: 0 0 40px 0;

        @include tablet-big {
            width: 100%;
            margin: 0 0 12px 0; }

        &__in {
            @include link;

            display: block;
            position: relative;
            height: 0;
            padding-bottom: 78.5%;
            border-radius: 4px;
            overflow: hidden;

            &:hover {
                .portfolio {
                    &-item {
                        &__visual {
                            transform: translate3d(0,0,0) scale(1.1); } } } } }

        &__visual {
            @include transition;

            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            &::after {
                content: ' ';
                position: absolute;
                top: 50%;
                right: 0;
                bottom: 0;
                left: 0;
                background: linear-gradient(0deg, #222 0%, rgba(72, 72, 72, 0) 100%); } }

        &__img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%; }

        &__desc {
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            padding: 40px;

            @include tablet-big {
                padding: 24px; } }

        &__title {
            @include main-text;

            display: block;
            margin: 0 0 12px 0;
            color: $c-white;
            text-transform: uppercase; }

        &__headline {
            @include head;

            color: $c-white; } } }
