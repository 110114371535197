.tab-content > .tab-pane {
    display: block;
    height: 0;
    overflow: hidden;
}

.tab-content > .tab-pane.active {
    height: auto;
}

.nav {
    &-tabs {
        &--product {
            display: flex;
            margin: 0 0 32px 0;

            @include tablet-big {
                white-space: nowrap;
                overflow-x: auto;
                margin: 0 -16px 24px -16px;
                padding: 0 0 0 16px;

                &::-webkit-scrollbar,
                &::-webkit-scrollbar-track,
                &::-webkit-scrollbar-thumb {
                    display: none;
                    visibility: hidden;
                    opacity: 0;
                }
            }

            li {
                width: 25%;
                height: 60px;
                line-height: 60px;
                text-align: center;

                @include tablet-big {
                    width: auto;
                    height: 52px;
                    line-height: 50px;
                    box-sizing: border-box;
                }

                &:last-of-type {
                    @include tablet-big {
                        padding: 0 16px 0 0;
                    }
                }

                & > a {
                    @include link;
                    @include main-text;

                    line-height: 60px;
                    display: block;
                    width: 100%;
                    height: 100%;
                    color: $c-black;
                    box-shadow: 1px 0 0 0 $c-gray, 0 1px 0 0 $c-gray,
                        1px 1px 0 0 $c-gray, 1px 0 0 0 $c-gray inset,
                        0 1px 0 0 $c-gray inset;
                    box-sizing: border-box;

                    @include tablet-big {
                        box-shadow: none;
                        line-height: 50px;
                        padding: 0 32px;
                        border-top: 1px solid $c-gray;
                        border-right: 1px solid $c-gray;
                        border-bottom: 1px solid $c-gray;
                    }
                }

                &.active {
                    a {
                        background: $c-gray;
                    }
                }

                &:last-of-type {
                    a {
                        border-right: 1px solid $c-gray;
                    }
                }

                &:first-of-type {
                    @include tablet-big {
                        a {
                            border-left: 1px solid $c-gray;
                        }
                    }
                }
            }
        }
    }
}

.tab {
    &-content {
        &--product {
            p {
                @include main-text;

                + p {
                    margin: 32px 0 0 0;

                    @include tablet-big {
                        margin: 20px 0 0 0;
                    }
                }
            }
        }
    }
}

.zoomContainer {
    pointer-events: none;
}
