.about {
    &-us {
        &__img {
            display: block;
            width: 100%;
            height: auto;
            margin: 0 0 60px 0;
            border-radius: 4px;
            overflow: hidden;

            @include tablet-big {
                margin: 0 0 24px 0; } }

        &__item {
            margin: 0 0 80px 0;

            @include tablet-big {
                margin: 0 0 48px 0; } }

        &__headline {
            margin: 0 0 32px 0;

            @include tablet-big {
                margin: 0 0 12px 0; } }

        &__desc {
            p {
                @include main-text;

                color: $c-black;

                + p {
                    margin: 16px 0 0 0; } } }

        &__approve {
            display: flex;
            justify-content: space-between;

            @include tablet-big {
                flex-direction: column;
                justify-content: flex-start; }

            .about-us {
                &__item {
                    flex: 0 0 660px;

                    @include tablet-big {
                        flex: 0 0 auto; } }

                &__certificate {
                    flex: 0 0 280px;
                    align-self: flex-start;

                    @include tablet-big {
                        flex: 0 0 auto; } } } }

        &__certificate {
            position: relative;
            width: 100%;
            box-shadow: 0px 0px 12px rgba(48, 48, 48, 0.07);

            &::after {
                content: ' ';
                position: absolute;
                top: 12px;
                right: 12px;
                width: 26px;
                height: 24px;
                background: url('../img/icons/zoom.svg') center center no-repeat;
                background-size: contain; }

            img {
                display: block;
                width: 100%; } } } }
